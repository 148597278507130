import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useDateFormat } from '../../hooks/useDateFormat';
import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';
import { Box, Building, ErrorType } from '../../utils/api';
import { BoxError, Lift } from '../../utils/types';
import { Dropdown } from '../form/Dropdown';
import { replaceMapKey } from '../map/placeUtils';
import { ErrorCard } from './ErrorCard';
import { Hint } from './Hint';
import { Spacer } from './Spacer';
import { Stack } from './Stack';
import { StatusType } from './StatusIcon';

const CardLink = styled(Link)`
  text-decoration: none;
`;

export type FullBoxError = BoxError & {
  box?: Box & {
    lift?: Lift & {
      building?: Building;
    };
  };
};

type ErrorListProps = {
  errors: FullBoxError[];
  showFilterHint?: boolean;
};

export function ErrorList({ errors, showFilterHint = false }: ErrorListProps) {
  const t = useTranslation();
  const dateFormat = useDateFormat();
  const { formatDate, formatTime } = dateFormat;

  const [sortBy, setSortBy] = useState<'date' | 'floorNumber'>('date');
  const sortedErrors = [...errors].sort((a, b) => {
    if (sortBy === 'floorNumber') {
      const floorA = a.floorNumber !== null ? a.floorNumber : Infinity;
      const floorB = b.floorNumber !== null ? b.floorNumber : Infinity;
      return floorA - floorB;
    }
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <Stack gap={space[2]}>
      <Dropdown
        label="Sort by"
        value={sortBy}
        onChange={(e) => setSortBy(e.target.value as 'date' | 'floorNumber')}
      >
        <option value="date">{t.sortByDate}</option>
        <option value="floorNumber">{t.sortByFloor}</option>
      </Dropdown>
      <Spacer />
      {sortedErrors.length === 0 && (
        <>
          {showFilterHint && <Hint>{t.errorsEmptyHint}</Hint>}
          <p>{t.errorsEmpty}</p>
        </>
      )}
      {sortedErrors.map((error, i) => {
        const lift = error.box?.lift;
        const building = lift?.building;
        const buildingId = lift?.building_id;
        const statusType = mapErrorType(error.type);

        return (
          <CardLink
            key={i}
            to={
              lift && buildingId
                ? `/building/${buildingId}/lift/${lift.id}`
                : ''
            }
          >
            <ErrorCard
              status={statusType}
              title={error.message}
              // subTitle={formatRelative(err.date)}
              image={replaceMapKey(building?.photo)}
            >
              <div>
                <b>{formatDate(error.date)}</b> {formatTime(error.date)}
              </div>
              <div>
                {error.floorNumber !== null ? (
                  <>
                    Floor: {error.floorNumber}{' '}
                    (
                    <span>
                      {error.isFloorPredicted ? t.predicted : t.measured}
                    </span>
                    {error.duringLiftMovement !== null && (
                      <span>
                        {' '}
                        {error.duringLiftMovement ? t.duringLiftMovement : t.betweenDoorCycle}
                      </span>
                    )}
                    )
                  </>
                ) : (
                  error.floorNumber === null &&
                  error.isFloorPredicted === null &&
                  error.duringLiftMovement === null && (
                    <div>{t.noFloorInfoAvailable}</div>
                  )
                )}
              </div>
              {lift && (
                <div>
                  {lift?.name} {building && <span>– {building.name}</span>}
                </div>
              )}
            </ErrorCard>
          </CardLink>
        );
      })}
    </Stack>
  );
}

const statusMap: Record<ErrorType, StatusType> = {
  fatalerror: 'fatal',
  error: 'error',
  warning: 'warning',
  info: 'success',
};

const mapErrorType = (type: string): StatusType => statusMap[type as ErrorType];
