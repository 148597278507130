import React from 'react';

import { Translations } from './en';

export const it: Translations = {
  aborted: 'Interrotto',
  accelerationDescription:
    'Il fattore di accelerazione che viene utilizzato per il calcolo della curva.',
  accelerationNote:
    'Questo fattore deve essere sufficientemente alto per raggiungere le velocità impostate in caso contrario la porta non raggiungerà la velocità richiesta.',
  accelerationTitle: 'Accelerazione',
  acceptTerms: 'Accetto le condizioni di utilizzo',
  add: 'Aggiungi',
  addBuilding: 'Aggiungi edificio',
  addLift: 'Aggiungi ascensore',
  addressHint: "Inserire il nome dell'edificio/azienda o l'indirizzo.",
  adjustCameraSettings:
    'È inoltre possibile regolare le impostazioni della fotocamera dal menu a tendina.',
  admin: 'Amministratore',
  alreadyHasSubcontractorsError:
    'In qualità di subappaltatore, non puoi avere subappaltatori. Si prega di rimuovere i subappaltatori esistenti prima di accettare questa invito.',
  all: 'Tutti',
  allBuildings: 'Tutti gli edifici',
  allBuildingsSubscription: 'Da tutti gli edifici',
  alphabetical: 'Alfabetico',
  ambientTemperature: 'Temperatura box',
  assetNumber: 'ID ascensore',
  assetNumberHint:
    "Inserire l'ID dell'ascensore (codice impianto), se disponibile.",
  auto: 'Automatico',
  back: 'Indietro',
  backToDashboard: 'Torna alla dashboard',
  belt_tension: 'Tensione della cinghia',
  belt_tension_actions:
    "Regolare la tensione della cinghia come descritto nelle istruzioni per l'installazione.",
  beltclamp: 'Morsetto connessione cinghia-abbinamento',
  belt_tension_description: 'La tensione della cinghia è troppo alta/bassa.',
  betweenDoorCycle: 'Tra il ciclo della porta',
  bottom: 'in basso',
  bottomToTop: "Dal basso verso l'alto",
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `Il box è collegato a ${lift} in ${building}. Continuare?`,
  boxNotReady: 'Il box selezionato non è pronto.',
  boxOfflineHint:
    'Se si desidera aggiornare uno dei box elencati di seguito collegarlo prima a Internet.',
  boxScannedNoIdFound: 'Scatola scansionata, ma nessun ID trovato.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'Impossibile recuperare lo stato delle scatole: controlla la connessione internet della scatola e riprova. Se il problema persiste, contatta il tuo rappresentante Wittur.',
  boxSoftwareTitle: 'Software della Scatola',
  boxSoftwareUpdateError:
    'Aggiornamento del software della scatola non riuscito: controlla la connessione internet della scatola e riprova. Se il problema persiste, contatta il tuo rappresentante Wittur.',
  box_id: 'ID box',
  brakeParDescription:
    'La regolazione di questo parametro influisce sul ritardo e sullo scorrimento in fine corsa di apertura/chiusura (regolazione preimpostata a "5")..',
  brakeParNote:
    "Le regolazioni possibili sono ''0''..''9'' e la frenata più rapida si ottiene con il parametro ''0'' mentre il ritardo massimo si ottiene con il parametro \"9\".",
  brakeParTitle: 'Fine corsa meccanico serratura',
  buffer: 'Fine corsa meccanico serratura',
  building: 'Edificio',
  buildingHasNoElevator: 'Questo edificio non ha ascensori.',
  buildingName: 'Nome edificio',
  buildings: 'Edifici',
  buildingsWithoutValidAddress: 'Edifici senza indirizzo valido',
  bushingClose: 'Pulsante di chiusura',
  bushingOpen: 'Pulsante di apertura',
  cablechain: 'Catena guidacavi',
  cableTensorSpring: 'Molla tensione fune accoppiamento carrelli',
  callFloorsInstruction: (
    <>
      Apri la porta e <b>chiama tutti gli altri piani</b>. Esci dalla cabina e
      fai clic su "Continua"
    </>
  ),
  cameraMode: 'Modalità fotocamera',
  cancel: 'Annulla',
  car: 'Cabina',
  carDoor: 'Porta di cabina',
  carDoorCounter:
    'Contatore dei cicli porta della precedente scheda elettronica',
  carDoorModified: 'La porta di cabina è stata modificata',
  cd_counter_rollers_installation: 'Rotelle di contrasto',
  cd_counter_rollers_installation_actions:
    "Per ogni porta di piano, controllare che le rotelle possano essere ruotate facilmente a mano e che la loro distanza dal binario non sia eccessiva. In caso contrario, allentare le rotelle e regolarle di nuovo in modo che siano il più possibile vicine al binario, ma senza contatto, e possano essere ruotate facilmente a mano. Se è necessario smontare l'abbinamento per accedere alle rotelle, seguire le istruzioni per il montaggio.",
  cd_counter_rollers_installation_description:
    'Le rotelle di contrasto della porta di cabina non sono installate correttamente.',
  cd_roller: 'Rotelle porta di cabina',
  chooseFloorOrCardoor: 'Scegli porta di piano / di cabina',
  choosePartNumber: 'Scegli il codice articolo',
  chooseReason: 'Scegli un motivo',
  chooseStartingFloorInstructions:
    'Selezionare il piano di avvio della corsa di controllo. Il piano di avvio può essere il più basso o il più alto.',
  clearDriveErrors: 'Cancella',
  close: 'Chiudi',
  closeDoorInstructions: (floor: string) => (
    <>
      Verso il piano <b>{floor == 'più basso' ? 'più basso' : 'più alto'}</b> .
      Assicurati che la porta della cabina sia chiusa prima di procedere
    </>
  ),
  closeForcePotiDescription:
    "Impostazione del limite di forza utilizzato per l'apertura e la chiusura della porta.",
  closeForcePotiNote:
    "Regolabile tramite potenziometro sull'elettronica. Nelle elettroniche più recenti, come il MIDIV2, se necessario è possibile impostarlo in questa modalità (non è necessario regolarlo nelle applicazioni standard grazie all'autoapprendimento).",
  closeForcePotiTitle: 'Limite della forza di chiusura',
  closeHoldForceDescription: 'Forza che viene applicata a fine chiusura.',
  closeHoldForceNote:
    "Tale forza dovrebbe essere compresa tra 50 N e 70 N. Una forza troppo bassa causerà aperture indesiderate dell'abbinamento. Una forza troppo alta riscalderà inutilmente il motore, che potrebbe funzionare in modalità ridotta.",
  closeHoldForceTitle: 'Forza (mantenuta) di fine chiusura',
  closeSpeedDescription:
    'Velocità massima della porta in direzione di chiusura. La curva di chiusura viene calcolata in modo da raggiungere la velocità selezionata, se possibile.',
  closeSpeedNote:
    'La velocità reale della porta dipende anche dagli interruttori DIP del controller della porta per la regolazione della velocità.  Regolazione alla velocità 4 significa il 100% del Valore 2.',
  closeSpeedTitle: 'Velocità di chiusura',
  closing_device_failure: 'Dispositivo di chiusura',
  closing_device_failure_actions:
    'Controllare che il dispositivo di chiusura per la porta di piano funzioni correttamente in tutti i suoi componenti (Spirator, molla di chiusura e contrappeso). La porta di piano deve potersi chiudere automaticamente da ogni posizione.',
  closing_device_failure_description:
    'Il dispositivo di chiusura non è installato correttamente. La forza necessaria per chiudere la porta è eccessiva.',
  companyName: "Nome dell'azienda",
  completed: 'Completato',
  componentDetails: 'Dettagli sui componenti',
  componentDetailsHint:
    "Per alcuni componenti esistono più varianti possibili. Si tratta di informazioni importanti per l'utilizzo della funzione di manutenzione preventiva. Fare clic sugli elementi elencati per selezionare la parte effettivamente installata.",
  confirm: 'Confermare',
  confirmDeleteBuilding: "Eliminare l'edificio?",
  confirmDeleteErrorHistory:
    'Vuoi davvero cancellare la cronologia degli errori?',
  confirmDeleteFavoriteBuilding: "Rimuovere l'edificio dai preferiti?",
  confirmDeleteLift: "Eliminare l'ascensore?",
  confirmInvite: 'Conferma e Collega',
  connectionErrorMessage:
    'Impossibile recuperare i dati a causa di un problema di connessione. Si prega di riprovare più tardi.',
  continue: 'Continua',
  contractorBuilding: "Edificio dell'appaltatore",
  contractorBuildings: "Edifici dell'appaltatore",
  contractors: 'Appaltatori',
  couplerelease: 'Sblocco abbinamento',
  couplerFriction: "Attrito dell'abbinamento",
  couplerMovementParameter: 'Parametro movimento abbinamento',
  couplerSpeedDescription:
    "Questo valore definisce la velocità massima della cinghia nell'area specifica dell'abbinamento.",
  couplerSpeedTitle: 'Velocità abbinamento',
  couplerWitdhDescription:
    "Questo valore definisce la distanza della cinghia nell'area dell'abbinamento. Questo valore risulta definita dopo la procedura di apprendimento.",
  couplerWitdhTitle: 'Larghezza abbinamento',
  currentVersion: 'Versione corrente',
  couplerwidth: 'Larghezza abbinamento',
  date: 'Data',
  delete: 'Elimina',
  deleteErrorHistory: 'Cancellare cronologia errori',
  directConnection: (
    <>
      <p>
        Connettiti al Wi-Fi del box ELEVATORSENSE eseguendo la scansione del
        codice QR stampato sul box con la fotocamera del tuo smartphone.
      </p>
      <p>
        Una volta connesso, torna a questa schermata e premi il pulsante{' '}
        <b>Continua</b> qui sotto.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Disconnetti Wireless Programming',
  divpulley: 'Puleggia di rinvio',
  documentation: 'Documentazione',
  doorContact: 'Contatto porta',
  doorCycleCounter: 'Contatore cicli porta',
  doorCycleError1: 'Nessun dato ricevuto sul ciclo della porta.',
  doorCycleError2: 'Il movimento del ciclo della porta è stato interrotto.',
  doorCycleError4: 'È stata rilevata una riapertura della porta.',
  doorCycleError5: 'È stata rilevata una richiusura della porta.',
  doorCycleError7: 'Nessun ciclo della porta valido ricevuto.',
  doorCycles: 'Cicli della porta',
  doorInstallationDate: 'Data di installazione della porta',
  doorFriction: 'Attrito della porta',
  doorIsClosed: 'Sì, la porta è chiusa',
  doorModel: 'Modello porta',
  doorModelNotSupported:
    'Il modello di porta selezionato non è disponibile per la manutenzione preventiva.',
  doorMovementParameter: 'Parametro movimento della porta',
  doorPosition: 'Posizione porta',
  doorSerialNumber: 'Numero di serie della porta',
  doorType: 'Tipo di porta',
  doorwidth: 'Larghezza porta',
  downloadCertificate: 'Scarica il certificato',
  downloadRequestedExtendedWarranty:
    'Scarica tutte le richieste di estensione della garanzia',
  downloadLicense: 'Scarica tutte le licenze come CSV',
  drive_motor_noise: 'Rumore del motore di azionamento',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  duringLiftMovement: 'Durante il movimento dell\'ascensore',
  editBuilding: 'Modifica edificio',
  editLift: 'Modifica ascensore',
  elecBoard: 'Scheda elettronica',
  elevatorIdNotFound: (
    <>
      Per richiedere l'estensione della garanzia, è necessario inserire il
      numero di serie della porta nelle impostazioni dell'ascensore.
      impostazioni dell'ascensore.
      <b>Cliccare qui per accedere alle impostazioni.</b>
    </>
  ),
  elevatorInstallationDate: "Data di installazione dell'ascensore",
  elevatorUsageType: "Tipo di utilizzo dell'ascensore",
  email: 'E-mail',
  encoderTemperature: 'Temperatura encoder',
  error: 'Errore',
  errorDescription: "Descrizione dell'errore",
  errorEarlierThanInstallationDate:
    'Non è possibile inserire una data precedente alla data di installazione.',
  errorInvalid: 'Inserire un valore valido.',
  errorInvalidOperation: 'Operazione non valida',
  errorMessages: 'Messaggi di errore',
  errorMessagesDescription: 'Display dei messaggi di errore registrati.',
  errorNotFound: 'Risorsa non trovata',
  errorRequired: 'Questo campo è obbligatorio.',
  errorRetryCamera:
    'Impossibile accedere alla fotocamera. Riprovo, la pagina verrà ricaricata...',
  errorUnknownOrForbiddenBox:
    'Il box non è disponibile. Contattare il servizio di assistenza Wittur per richiedere supporto.',
  errorUnableToAccessCamera:
    'Impossibile accedere alla fotocamera dopo diversi tentativi. Si prega di aggiornare la pagina o controllare i permessi della fotocamera.',
  errors: 'Errori',
  errorsEmpty: 'Nessun messaggio.',
  errorsEmptyHint: 'Verifica i filtri e, se necessario, modificali.',
  extendWarranty: 'Estensione della garanzia',
  fatalError: 'Errore irreversibile',
  fatalErrors: 'Errori irreversibili',
  favorite: 'Preferito',
  favorites: 'Preferiti',
  figureAdjustment: 'Regolazione',
  figureAugustaEVO: 'Esempio Augusta EVO',
  figureClosingWeight: 'Peso di chiusura',
  figureEco: 'ECO+',
  figureHydra: 'Esempio Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Non OK',
  figureOk: 'OK',
  figureSGV: 'Esempio SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Molla di chiusura',
  firstName: 'Nome',
  floor: 'Piano',
  floors: 'Piani',
  followingBoxScanned: 'La scatola seguente è stata scansionata',
  forgotPassword: 'Hai dimenticato la password?',
  forgotPasswordText:
    'Inserisci il tuo indirizzo e-mail per reimpostare la password.',
  groundFloor: 'Piano terra',
  home: 'Pagina iniziale',
  incompleteElevatorSettings:
    "Per utilizzare Preventive Maintenance abbiamo bisogno di maggiori informazioni su questo ascensore. Vai alle impostazioni dell'ascensore e inserisci le informazioni mancanti.",
  initialized: 'Inizializzato',
  installationControl: 'Installation Control',
  installationControlDescription:
    'Esegui controlli automatici per rilevare problemi di installazione comuni.',
  installationControlExecutedBy: 'Eseguito da',
  installationControlHeadline: "Ultimo stato dell'installazione",
  installationControlHistory: 'Cronologia corse',
  installationControlHistoryEmpty: 'Nessuna corsa di installazione registrata',
  installationControlInstructions: 'Mostra istruzioni',
  installationControlSubheadline: 'Riepilogo di tutte le corse',
  installationDateErrorMessage:
    "Non è possibile inserire una data di installazione della porta precedente alla data di installazione dell'ascensore",
  instantSubscription: 'Notifica istantanea',
  instantSubscriptionExplanation: (
    <>
      {' '}
      La <b>notifica istantanea</b> è una notifica immediata inviata via e-mail
      non appena si verifica un errore irreversibile su un ascensore. Gli errori
      irreversibili sono errori che possono portare ad un malfunzionamento
      immediato dell'ascensore
    </>
  ),
  invitationExpired: "Il link dell'invito non è più valido.",
  inviteAsManager: "L'utente può invitare altri colleghi a registrarsi.",
  inviteCompanyWithSubcontractorsError:
    "L'azienda che stai cercando di invitare ha già dei subappaltatori e non può essere aggiunta come subappaltatore. Devono rimuovere i subappaltatori esistenti o scegliere un'altra azienda.",
  inviteCoworkers: 'Invita i colleghi',
  inviteSubcontractor: 'Invita un subappaltatore',
  inviteToSignUpMessage: (companyName: string) =>
    `L'azienda ${companyName} ti ha invitato a registrarti su ElevatorSense come loro subappaltatore. Si prega di compilare il modulo per registrarsi.`,
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} ti ha invitato a essere il loro subappaltatore su ElevatorSense.`,
  ipaddress: 'Indirizzo IP',
  joinText: (name: string) => (
    <>
      Iscriviti per unirti all'organizzazione <i>{name}</i>.
    </>
  ),
  landingDoor: 'Porta di piano - Piano',
  landing_lock_roller_adjustment:
    'Regolazione delle rotelle della serratura della porta di piano',
  landingCarDoorSpareParts: 'Ricambi porte di piano/cabina',
  lastError: 'Ultimo errore',
  lastExchangeDate: 'Ultima data di sostituzione',
  lastMaintenanceDate: "Data dell'ultima manutenzione",
  lastMaintenanceScope: 'Ultimo ambito di manutenzione',
  lastMaintenanceScopeHint:
    "Selezionare l'ambito di manutenzione raccomandato da Wittur che è stato effettivamente eseguito durante l'ultima visita di manutenzione.",
  lastName: 'Cognome',
  lastTimeDoorElectronicsChange:
    'Data di sostituzione della scheda elettronica della porta',
  latestSoftwarePackVersion: (version: string) =>
    `Versione del pacchetto software: ${version}`,
  ld_counter_rollers_installation: 'Rotelle di contrasto',
  ld_counter_rollers_installation_actions:
    "Per ogni porta di piano, controllare che le rotelle possano essere ruotate facilmente a mano e che la loro distanza dal binario non sia eccessiva. In caso contrario, allentare le rotelle e regolarle di nuovo in modo che siano il più possibile vicine al binario, ma senza contatto, e possano essere ruotate facilmente a mano. Se è necessario smontare l'abbinamento per accedere ai rulli, seguire le istruzioni per il montaggio.",
  ld_counter_rollers_installation_description:
    'Le rotelle di contrasto della porta di piano non sono installate correttamente.',
  ld_roller: 'Rotelle porta di piano',
  left: 'Sinistra',
  licenseRequired:
    'Non è stata acquistata una licenza valida per questo prodotto. Contatta il tuo referente commerciale Wittur per acquistare questo prodotto ElevatorSense.',
  lift: 'Ascensore',
  liftMovementDistance: "Movimento dell'ascensore",
  liftSetup: "Impostazione dell'ascensore",
  liftSetupComplete: 'Setup completato.',
  liftSetupError: 'I dati corrispondenti sono incompleti o mancanti.',
  liftSetupError40:
    "Il movimento dell'ascensore non corrisponde alla corsa di setup",
  liftSetupIncomplete: 'Setup incompleto.',
  liftSetupPending:
    "Eseguire il setup per la configurazione iniziale di ElevatorSense sull'ascensore. Senza questa operazione non sarà possibile utilizzare alcune funzionalità dei prodotti ElevatorSense o i risultati dei prodotti ElevatorSense potrebbero essere diversi. Fare clic qui per eseguire la configurazione.",
  liftSetupRepeat:
    'Corsa di setup non riuscita. Eseguire di nuovo la corsa seguendo il processo descritto.',
  lifts: 'Ascensori',
  linkBox: 'Collega il box',
  linkToWebshop: (link: string) => (
    <>
      Se hai una scatola ElevatorSense e desideri attivare una licenza, fai clic{' '}
      <a href={link} className="clickable-link">
        qui
      </a>
      .
    </>
  ),
  loading: 'Caricamento in corso',
  login: 'Accedi',
  loginText: "Esegui l'accesso con il tuo account.",
  logout: 'Logout',
  mandatory: 'Eseguire il controllo per la sostituzione',
  manageAccess: 'Gestire Accesso',
  manageElevators: 'Gestisci ascensori',
  manageSharedBuildingsAndElevator: 'Gestisci edifici/ascensori condivisi',
  manual: 'Manuale',
  maxElectronics: 'Ascensori max.',
  maxUsage: 'Utilizzo max.',
  measured: 'Misurato',
  members: 'Membri',
  misalignment_cd_vs_ld: 'Allineamento porta/abbinamento',
  misalignment_cd_vs_ld_actions:
    'Regolare le rotelle della serratura della porta di piano. La distanza tra le lame dello scivolo di abbinamento e le rotelle deve essere uguale a destra e a sinistra.',
  misalignment_cd_vs_ld_description:
    "L'abbinamento della porta di cabina non è allineato correttamente con le rotelle della serratura della porta di piano.",
  misalignment_cd_vs_ld_sill_gap: 'Distanza della soglia',
  misalignment_cd_vs_ld_sill_gap_actions:
    "La distanza della soglia a sinistra e a destra dell'apertura della porta non è uguale. Se ciò si verifica su tutti i piani, regolare la posizione della soglia della porta di cabina.",
  misalignment_cd_vs_ld_sill_gap_description:
    'La porta di piano non è parallela alla porta di cabina.',
  mode: 'Modalità',
  monitoring: 'Monitoraggio',
  motor: 'Motore',
  motorTemperature: 'Temperatura motore',
  myBuildingsSubscription: 'Solo dai miei preferiti',
  name: 'Nome',
  networkQuality: 'Qualità della rete',
  newInstallationControl: 'Nuovo controllo installazione',
  next: 'Succ',
  nextMaintenanceDate: 'Prossima data di manutenzione',
  no: 'No',
  notAuthorizedToConfirmInviteError:
    'Non sei autorizzato a confermare questo invito, poiché non è destinato al tuo utente connesso.',
  noBoxLinked:
    "L'ascensore non è ancora collegato ad un box ELEVATORSENSE. Per utilizzare tutte le funzionalità della app, eseguire la scansione del codice QR sul box oppure inserire manualmente l'id del box.",
  noBuildingsVisible:
    "Nessun edificio è visibile nell'attuale visualizzazione della mappa. Prova a regolare la mappa per vedere altri edifici.",
  noComponentsFound: 'Nessun componente trovato per questo piano',
  noFavorite: 'Non è un preferito',
  noFavoritesChoosen:
    "Non hai ancora aggiunto nessun edificio ai preferiti. Vai su un edificio e fai clic sull'icona della stella per aggiungerlo ai tuoi edifici preferiti.",
  noFloorInfoAvailable: 'Nessuna informazione sul piano disponibile',
  noSubcontractors: 'Nessun subappaltatore trovato.',
  noInstallationControlFound:
    "L'ESN della scheda della porta non corrisponde all'ESN dell'ultima corsa di Installation Control. Per favore, verifica di essere connesso all'ascensore corretto.",
  noSubcontractorMessage:
    'Attualmente non ci sono subappaltatori collegati alla tua azienda. Per stabilirne uno, invia un invito.',
  noSubscription: 'Nessuno',
  note: 'Nota',
  noQRCodeDetected:
    'Il codice QR non è stato rilevato ancora. Per favore, sposta la fotocamera in modo che il codice QR sia a fuoco e completamente visibile nella visuale.',
  nudgingSpeedDescription:
    'Velocità della porta in condizione di bassa velocità forzata. La curva di apertura/chiusura viene calcolata in modo da raggiungere la velocità selezionata, se la bassa velocità forzata è attiva.',
  nudgingSpeedNote:
    'La velocità reale della porta dipende anche dagli interruttori DIP del controller della porta per la regolazione della velocità.  Regolazione alla velocità 4 significa il 100% del Valore 3.',
  nudgingSpeedTitle: 'Velocità di regolazione',
  obsolete: 'Obsoleto',
  offline: 'Offline',
  offlineConnection:
    'Al momento sei offline, ma puoi utilizzare la funzione Wireless Programming.',
  ok: 'Manutenzione regolare',
  online: 'Online',
  openHoldForceDescription: 'Forza che viene applicata a fine apertura.',
  openHoldForceNote:
    "Tale forza dovrebbe essere compresa tra 50 N e 60 N. Una forza troppo bassa causerà aperture e chiusure indesiderate. Una forza troppo alta all'estremità riscalderà inutilmente il motore, che potrebbe funzionare in modalità ridotta.",
  openHoldForceTitle: 'Forza (tenuta) estremità di apertura',
  openSpeedDescription:
    'Velocità massima della porta in direzione di apertura. La curva di apertura viene calcolata in modo da raggiungere la velocità selezionata, se possibile.',
  openSpeedNote:
    'La velocità reale della porta dipende anche dagli interruttori DIP del controller della porta per la regolazione della velocità.  Regolazione alla velocità 4 significa il 100% del Valore 1.',
  openSpeedTitle: 'Velocità di apertura',
  otherReason: 'Altro',
  overdue: 'In ritardo',
  parameter: 'Parametro',
  partInformation: 'Informazioni sul Componente',
  partIsStillOk: 'La parte è ancora OK',
  partNumber: 'Numero di Parte',
  partName: 'Nome della Parte',
  password: 'Password',
  passwordResetSent:
    "Ti abbiamo inviato un'e-mail con le istruzioni per reimpostare la password. Controlla la posta in arrivo.",
  pendingInvites: 'Inviti in sospeso',
  pendingUserInvites: 'Inviti utente in sospeso',
  pendingSubcontractorInvites: 'Inviti di subappaltatore in sospeso',
  planningAndAlerts: 'Pianificazione e avvisi',
  position: 'Posizione',
  positionOutDescription:
    'Immettendo questo parametro il relè di posizione (relativo a FPC) può essere regolato per commutarsi in qualsiasi posizione della porta. Il valore di regolazione predefinito è 0 (disattivata).',
  positionOutNote:
    'Nel caso in cui questo parametro venga impostato su un altro valore, il relè si attiva una volta raggiunta la posizione. Questa funzione viene utilizzata quasi esclusivamente nei vani con cabine in movimento anticipato.',
  positionOutTitle: 'Posizione esterna',
  postpone: 'Posticipa',
  powerReductionFactor: 'Fattore di riduzione della potenza',
  predicted: 'Previsto',
  prev: 'Prec',
  preventiveMaintenance: 'Preventive Maintenance',
  preventiveMaintenanceDescription:
    "Sostituisci i pezzi di ricambio della porta dell'ascensore prima che creino problemi.",
  preventiveMaintenanceUnavailable:
    "I dati inseriti sono completi! ElevatorSense calcolerà ora il programma di manutenzione in base all'utilizzo dell'ascensore. Questo processo potrebbe richiedere alcuni minuti.",
  priority: 'Priorità',
  product: 'Prodotto',
  programmingDisabledHint:
    "La programmazione dell'elettronica è attualmente disabilitata dall'hardware. Assicurarsi che l'interruttore DIP 1 sia attivato per consentire la programmazione del dispositivo.",
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Impossibile aprire la finestra. Assicurarsi che i popup non siano bloccati dal browser.',
  programmingErrorRequest:
    'Richiesta non riuscita. Assicurarsi che il dispositivo sia connesso alla rete Wi-Fi del box ELEVATORSENSE.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription: 'Configura facilmente i parametri della porta.',
  pulley_is_touching_belt: 'Puleggia',
  pulley_is_touching_belt_actions:
    "Regolare l'allineamento della cinghia in modo tale che non tocchi i bordi della flangia della puleggia.",
  pulley_is_touching_belt_description:
    'La flangia della puleggia è in contatto con la cinghia.',
  readyToRunTest: 'Pronto per eseguire il test.',
  realtimeMonitoringDescription:
    'Visualizza i dati raccolti dai sensori e i grafici di movimento delle porte in tempo reale.',
  realtimeMonitoringTitle: 'Real Time Diagnostics',
  recommended: 'Pianificare il controllo per la sostituzione',
  recommendedActions: 'Azioni suggerite',
  recommendedDateOfExchange: 'Data consigliata per la sostituzione',
  reload: 'Aggiorna',
  remove: 'Rimuovi',
  reopenTimeDescription:
    'Il parametro di riapertura definisce il periodo di attesa dopo il movimento di riapertura automatico in posizione di apertura, prima di chiudersi nuovamente.',
  reopenTimeNote:
    "Questa funzione viene utilizzata in caso di procedura di riapertura attivata dalla scheda elettronica della porta e non dal controller principale dell'ascensore.",
  reopenTimeParameter: 'Parametro tempo di riapertura',
  reopenTimeTitle: 'Tempo di riapertura',
  repeatFloor:
    "Ripeti la procedura di controllo dell'installazione per questo piano!",
  repeatWholeRun: (
    <>
      Ripetere <b>tutta</b> la procedura di controllo dell'installazione!
    </>
  ),
  replaced: 'Sostituisci ora',
  replacementWasPostponed: 'La sostituzione è stata posticipata in data:',
  requestNewLink: 'Richiedi un nuovo link',
  resetPassword: 'Reimposta la password',
  resetPasswordText: 'Inserisci una nuova password.',
  revoke: 'Revoca',
  right: 'Destra',
  rollerBumper: 'Rotella in gomma di battuta',
  rollerSynchronisationRope: 'Rotella per fune di sincronizzazione carrelli',
  runs: 'Corse',
  save: 'Salva',
  scan: 'Esegui la scansione',
  scanned: 'Scansionato',
  scanAgain: 'Scansionare di nuovo',
  searchPlaces: 'Ricerca luogo',
  select: 'Seleziona',
  selectCameraMode: 'Seleziona modalità fotocamera',
  selectDoorModel: 'Seleziona il modello di porta',
  selectDoorType: 'Seleziona Tipo di porta',
  selectModifiedFloors:
    "Seleziona i piani su cui sono state apportate modifiche dall'ultima corsa di prova. I piani su cui si sono verificati errori durante l'ultima corsa sono preselezionati.",
  selectPartsHint:
    "Verifica quale parte è installata e fare clic sull'immagine corrispondente.",
  send: 'Invia',
  sendResults: 'Invia risultati',
  sendTo: 'Invia i risultati ai seguenti indirizzi e-mail',
  sensorData: 'Dati del sensore',
  serviceDriveSwitch: 'Interruttore di azionamento modalità manutenzione',
  settings: 'Impostazioni',
  share: 'Condividi',
  shareBody: (link: string) => `Visualizza il risultato del test:\n${link}`,
  shareResults: 'Condividi i risultati',
  shareSubject: 'Risultati ELEVATORSENSE',
  shoe: 'Pattino di scorrimento anta',
  showPassedTests: 'Mostra i test superati ...',
  showTerms: 'Mostra le condizioni di utilizzo',
  signUp: 'Iscriviti',
  skid: 'Azionamento serratura di piano porte a battente',
  slideingshoe_failure: 'Malfunzionamento del pattino a scorrimento',
  slideingshoes_usage: 'Utilizzo dei pattini a scorrimento',
  somethingWentWrong: 'Qualcosa è andato storto. Riprova.',
  sortBy: 'Ordina per',
  sortByDate: 'Data',
  sortByFloor: 'Numero del piano',
  sparePartDoesNotFit: 'Pezzo di ricambio non compatibile',
  sparePartNotAvailable: 'Pezzo di ricambio non disponibile',
  speed: 'Velocità',
  start: 'Avvia',
  startNewTestRun: 'Nuova corsa di prova',
  started: 'In esecuzione',
  starting_floor: 'Piano di avvio',
  status: 'Stato',
  subcontractorNotFound: 'Subappaltatore non trovato',
  subcontractors: 'Subappaltatori',
  subcontractorManagement: 'Gestione dei subappaltatori',
  subscriptionHint:
    'Riceverai le notifiche via e-mail solo se hai acquistato i prodotti appropriati per il box ElevatorSense.',
  subscriptionText: 'Seleziona quali notifiche desiderI ricevere via e-mail.',
  subscriptions: 'Notifiche',
  summarySubscription: 'Report giornaliero',
  summarySubscriptionExplanation: (
    <>
      Il <b>report giornaliero</b> include un'e-mail con una panoramica di tutti
      gli edifici e gli ascensori selezionati con l'indicazione del numero di
      errori
    </>
  ),
  temperature: 'Temperatura',
  termsOfUse: {
    general: {
      title: 'GENERAL TERMS AND CONDITIONS OF USE OF ELEVATORSENSE APPLICATION',
      sections: [
        {
          title: 'I. Generale',
          content: [
            <>
              Wittur GmbH (di seguito <strong>"WITTUR"</strong>,{' '}
              <strong>"Noi"</strong>) offre agli utenti (
              <strong>"Utente"</strong>), direttamente o tramite Affiliate come
              definite di seguito, una soluzione composta da un hardware che
              raccoglie dati da ascensori per passeggeri e merci in edifici o
              loro componenti (la <strong>"ElevatorSense Box"</strong>) e
              dall’applicazione mobile e web di ElevatorSense (di seguito{' '}
              <strong>"Mobile App"</strong>, <strong>"Web App"</strong> e
              congiuntamente <strong>"App"</strong>). (Sia la ElevatorSense Box
              che l’App sono indicate di seguito come{' '}
              <strong>"ElevatorSense Solution"</strong>).
            </>,
            <>
              La fornitura della ElevatorSense Box sarà disciplinata da un
              contratto separato tra l’Utente e WITTUR (
              <strong>"Contratto Box"</strong>).
            </>,
            <>
              L’App può essere offerta anche da Affiliate di WITTUR, restando
              tuttavia WITTUR l’unica parte contrattuale dell’Utente (come
              definito di seguito) in tale scenario per quanto riguarda la
              fornitura dell’App. <strong>"Affiliate"</strong> indica tutte le
              società a maggioranza partecipata o coinvolte in partecipazioni
              azionarie o interessi di capitale maggioritari tra loro, società
              dipendenti e controllanti, società sotto la direzione unificata di
              un’unica società di controllo, nonché società che detengono
              reciproci interessi o partecipazioni.
            </>,
            'WITTUR fornisce l’App esclusivamente a imprenditori. Un imprenditore è una persona fisica o giuridica o una società con capacità giuridica che, al momento della conclusione di un atto giuridico, agisce nell’esercizio della propria attività commerciale, imprenditoriale o professionale. I consumatori non possono utilizzare l’App.',
            <>
              I seguenti termini disciplinano interamente il rapporto giuridico
              tra WITTUR e l’Utente in relazione all’App (
              <strong>"Termini di Utilizzo"</strong>) e riguardano{' '}
              <i>inter alia</i> l’accesso e l’uso dell’App, fatte salve
              eventuali condizioni aggiuntive o divergenti come specificato
              nell’<a href="#appendix_1">Appendice 1</a>. I Termini di Utilizzo
              diventano vincolanti al completamento della registrazione da parte
              dell’Utente e a quel punto viene concluso un contratto di servizio
              con WITTUR (<strong>"Contratto"</strong>). I Termini di Utilizzo
              sono conservati da WITTUR e possono essere consultati dall’Utente
              in qualsiasi momento all’interno dell’App.
            </>,
            'In quanto imprenditore, l’Utente non ha alcun diritto di recesso.',
          ],
        },
        {
          title: 'II. Ambito',
          content: [
            <>
              L’App è un’applicazione mobile e web che consente i servizi
              descritti nel Contratto Box (<strong>"Servizi"</strong>). L’App
              fornisce inoltre informazioni, visualizzazioni, notifiche,
              istruzioni e valutazioni sulle misure appropriate da adottare (
              <strong>"Raccomandazioni"</strong>). All’interno dell’App,
              unitamente alle Raccomandazioni, viene fornito all’Utente un
              collegamento ipertestuale allo shop online di WITTUR per i
              componenti della Soluzione ElevatorSense.
            </>,
            <>
              L’utilizzo dell’App è soggetto esclusivamente ai presenti Termini
              di Utilizzo, nonché alle informazioni fornite all’interno
              dell’App. Condizioni divergenti o in conflitto non costituiscono
              parte di un accordo con WITTUR, a meno che WITTUR non le abbia
              espressamente accettate per iscritto. Nonostante la frase
              precedente, l’Utente è consapevole che, oltre ai presenti Termini
              di Utilizzo, potrebbe essere richiesto di rispettare ulteriori
              termini di utilizzo dell’operatore dello store di applicazioni (ad
              es. Google nel caso di Google Play Store o Apple nel caso di Apple
              App Store) se l’Utente utilizza la Mobile App scaricata dal
              rispettivo store, e che WITTUR non ha alcuna influenza su tali
              ulteriori termini di utilizzo, pertanto non si assume alcuna
              responsabilità in merito.
            </>,
            'Il download della Mobile App richiede dispositivi terminali idonei e l’accesso a Internet, il che può comportare costi per la connessione a Google Play Store / Apple App Store.',
            <>
              Le Parti riconoscono e convengono che i Servizi da fornire nelle
              giurisdizioni locali specificate nell’
              <a href="#appendix_1">Appendice 1</a> richiedono condizioni o
              requisiti aggiuntivi o divergenti, da aggiungere o sostituire a
              quelli previsti nei presenti Termini di Utilizzo. Pertanto, il
              rapporto contrattuale tra le Parti è soggetto a condizioni
              aggiuntive o divergenti come specificato nell’
              <a href="#appendix_1">Appendice 1</a>, se applicabile (di seguito:{' '}
              <strong>"Termini per le Giurisdizioni Locali"</strong>). In caso
              di conflitto tra i presenti Termini di Utilizzo e i Termini per le
              Giurisdizioni Locali, questi ultimi prevarranno.
            </>,
          ],
        },
        {
          title: 'III. Remunerazione',
          content: [
            'L’utilizzo delle funzionalità di base dell’App è gratuito. Tuttavia, l’Utente è consapevole che l’App può essere utilizzata solo insieme alla ElevatorSense Box, per la quale esistono diversi modelli di acquisto o noleggio, soggetti al Contratto Box.',
            <>
              Possono insorgere costi aggiuntivi per funzioni speciali
              disponibili all’interno dell’App, che l’Utente può acquistare (
              <strong>"Funzionalità"</strong>). Le Funzionalità disponibili per
              l’Utente possono variare a seconda del paese in cui l’App viene
              utilizzata.
            </>,
          ],
        },
        {
          title: 'IV. Fornitura dell’App; Manutenzione',
          content: [
            <>
              WITTUR fornirà un accesso e un utilizzo ragionevoli dell’App. A
              tal fine, WITTUR renderà l’App accessibile ai dipendenti
              registrati, permanenti e/o freelance dell’Utente (
              <strong>"Personale Autorizzato"</strong>).
            </>,
            'A esclusiva e assoluta discrezione di WITTUR, l’accesso all’App potrà essere ristretto, in tutto o in parte, temporaneamente o permanentemente, a causa di lavori di manutenzione, problemi di capacità o altri eventi al di fuori del suo controllo. WITTUR di norma eseguirà la manutenzione dell’App al di fuori degli orari lavorativi normali, a meno che un evento immediato non richieda, a esclusiva e assoluta discrezione di WITTUR, la manutenzione durante gli orari lavorativi normali. Qualsiasi parte dell’App può cambiare senza preavviso in qualsiasi momento, a esclusiva e assoluta discrezione di WITTUR.',
            'WITTUR fornisce l’accesso alla Web App in un ambiente ospitato, che consente all’Utente di utilizzare l’App tramite Internet senza dover installare e gestire il software sulla propria infrastruttura IT.',
            'WITTUR si adopererà in modo ragionevole per mantenere la disponibilità della Web App per l’uso da parte dell’Utente al punto di interconnessione tra Internet pubblico e la rete dei server di hosting di WITTUR. La Web App è considerata disponibile se è utilizzabile presso tale punto di interconnessione.',
            <>
              WITTUR fornisce aggiornamenti dell’App in conformità con lo stato
              dell’arte riconosciuto, senza remunerazione aggiuntiva (
              <strong>"Aggiornamenti"</strong>). Gli Aggiornamenti ai sensi di
              questi Termini di Utilizzo sono caratterizzati da correzioni di
              errori o altre piccole migliorie, senza funzionalità nuove
              significative. Gli Aggiornamenti non includono la fornitura di
              nuove funzionalità o componenti aggiuntivi offerti separatamente.
            </>,
            <>
              L’Utente deve installare gli Aggiornamenti per la Mobile App (cioè
              lato client) non appena disponibili. Gli Aggiornamenti consentono
              alla Mobile App di ricevere informazioni aggiornate e di
              garantirne il corretto funzionamento. In caso contrario, WITTUR
              non può assicurare che la Mobile App funzioni correttamente o che
              le informazioni fornite siano aggiornate. Se l’Utente non installa
              gli Aggiornamenti ma continua comunque a utilizzare la versione
              obsoleta della Mobile App, l’Utente rinuncia a qualsiasi reclamo
              relativo a difetti e a qualsiasi richiesta di risarcimento danni a
              cui potrebbe avere diritto.
            </>,
          ],
        },
        {
          title: 'V. Account Utente',
          content: [
            <>
              L’accesso all’App può essere concesso all’Utente e al suo
              Personale Autorizzato solo dopo la registrazione e la creazione di
              un account utente all’interno dell’App (
              <strong>"Account Utente"</strong>).
            </>,
            'L’Utente deve comunicare a WITTUR il proprio Personale Autorizzato.',
            'L’Utente è il solo ed esclusivo responsabile della gestione del proprio Personale Autorizzato, ad esempio assegnando ruoli e diritti all’interno dell’App. In particolare, l’Utente deve interrompere l’accesso all’App una volta che le persone interessate non lavorano più per l’Utente.',
            <>
              L’Utente è obbligato a mantenere segreti i dati di accesso al
              proprio Account Utente e a proteggerli dall’accesso da parte di
              terzi. Nel caso in cui l’Utente venga a conoscenza della perdita o
              dell’uso improprio dei propri dati di accesso o sospetti un uso
              improprio del proprio account utente, deve informare
              immediatamente WITTUR e cambiare la password utilizzando la
              funzionalità messa a disposizione nell’App. È vietato divulgare i
              dati di accesso a terzi. L’Utente non può utilizzare i dati di
              accesso di un’altra persona per accedere all’App. L’Utente è il
              solo ed esclusivo responsabile delle attività di chiunque acceda
              all’App utilizzando i dati di accesso dell’Utente, incluso il
              Personale Autorizzato, anche se tali attività non sono state, in
              realtà, autorizzate dall’Utente.
            </>,
          ],
        },
        {
          title: 'VI. Obblighi dell’Utente',
          content: [
            <>
              L’Utente è il solo ed esclusivo responsabile di qualsiasi
              contenuto che carica, pubblichi o renda diversamente disponibile
              al pubblico tramite l’App. Utilizzando un account sull’App,
              l’Utente dichiara e garantisce a WITTUR che le informazioni
              fornite a WITTUR (<strong>"Contenuti dell’Utente"</strong>) sono
              vere, accurate e complete. Inoltre, l’Utente garantisce di
              mantenere i Contenuti dell’Utente veri, accurati e completi.
              WITTUR non controlla i Contenuti dell’Utente per completezza,
              accuratezza, legalità, disponibilità, qualità o idoneità a
              qualsiasi scopo.
            </>,
            <>
              I Servizi possono essere eseguiti solo mentre la ElevatorSense Box
              è connessa a Internet. L’Utente riconosce che è sua sola ed
              esclusiva responsabilità rendere tecnicamente disponibile la
              ElevatorSense Box. Con la presente, l’Utente rinuncia a qualsiasi
              diritto e alle relative pretese cui potrebbe aver diritto a causa
              della mancata connettività della ElevatorSense Box.
            </>,
            'Al fine di eseguire i Servizi, l’Utente è obbligato a seguire scrupolosamente le istruzioni di installazione e funzionamento fornite nell’App.',
            <>
              Se WITTUR fornisce aggiornamenti e/o upgrade scaricabili per la
              ElevatorSense Box, l’Utente è obbligato a installarli per
              assicurare una corretta interazione tra l’App e la ElevatorSense
              Box. In caso contrario, WITTUR non può garantire che i Servizi
              possano essere eseguiti correttamente. Qualora l’Utente non
              installi un aggiornamento e/o upgrade fornito, ma continui
              comunque a utilizzare la versione obsoleta della ElevatorSense
              Box, l’Utente rinuncia a qualsiasi pretesa relativa a difetti e a
              eventuali richieste di risarcimento danni cui potrebbe aver
              diritto.
            </>,
            'È vietato all’Utente svolgere qualsiasi attività sull’App o in relazione ad essa che violi la legislazione applicabile o i diritti di terzi.',
            {
              text: 'Inoltre, è vietato all’Utente eseguire le seguenti attività, indipendentemente da qualsiasi violazione di legge:',
              list: [
                'la diffusione di virus, trojan e altri file dannosi;',
                'l’invio di posta indesiderata (junk mail), spam e catene di Sant’Antonio;',
                'la diffusione di contenuti o comunicazioni offensivi, a sfondo sessuale, osceni o diffamatori, nonché di tali contenuti o comunicazioni idonei a promuovere o supportare razzismo, fanatismo, odio, violenza fisica o atti illegali (in ciascun caso esplicitamente o implicitamente);',
                'la richiesta ad altri utenti di rivelare password o dati personali per scopi commerciali o illegali;',
                'la diffusione e/o riproduzione pubblica dei contenuti disponibili sull’App.',
              ],
            },
            'WITTUR si riserva il diritto di sospendere l’Utente dall’utilizzo dell’App in qualsiasi momento, qualora vengano violate le presenti Condizioni di Utilizzo, la normativa applicabile o i diritti di terzi, o qualora WITTUR abbia ragionevoli motivi per ritenere che possano essere violate.',
          ],
        },
        {
          title: 'VII. Diritti di Proprietà Intellettuale',
          content: [
            <>
              WITTUR concede all’Utente un diritto revocabile, non esclusivo,
              non trasferibile, non sublicenziabile e limitato nel tempo alla
              durata del Contratto, di utilizzare l’App in conformità con i
              presenti Termini di Utilizzo (<strong>"Licenza"</strong>). Il
              titolare dei diritti di proprietà intellettuale sull’App è WITTUR,
              o i suoi partner commerciali o terzi che hanno messo a
              disposizione di WITTUR i relativi contenuti e diritti di proprietà
              intellettuale in licenza. Pertanto, l’App e i contenuti resi
              disponibili sull’App non possono essere modificati, estesi,
              elaborati, copiati e/o altrimenti distribuiti dall’Utente.
            </>,
            <>
              Le raccomandazioni generate dall’App possono essere utilizzate
              dall’Utente per supportare i propri servizi nei confronti dei
              clienti dell’Utente. WITTUR non si assume alcuna responsabilità o
              obbligo in merito a tali servizi né per le azioni dell’Utente o
              dei suoi clienti che ne derivano.
            </>,
            <>
              Salvo quanto diversamente previsto nei presenti Termini di
              Utilizzo, l’Utente si impegna a non riprodurre, distribuire,
              modificare o creare opere derivate dall’App o da qualsiasi altro
              suo componente, e a non procedere al reverse engineering o alla
              decompilazione dell’App, se non nella misura in cui ciò sia
              autorizzato dalla legge imperativa.
            </>,
            <>
              L’Utente riconosce e accetta che WITTUR è autorizzata a utilizzare
              i dati elaborati tramite l’App in forma anonima (ai sensi delle
              leggi applicabili in materia di protezione dei dati) per qualsiasi
              scopo commerciale di WITTUR e, in particolare, a riprodurli e a
              concederne l’accesso a terzi. WITTUR non provvederà alla
              de-anonimizzazione dei dati ed è obbligata ad adottare tutte le
              misure appropriate per prevenire qualsiasi de-anonimizzazione dei
              dati. Qualora venga concesso l’accesso a terzi, WITTUR dovrà
              obbligare tali terzi a non de-anonimizzare i dati e ad adottare
              tutte le misure appropriate per prevenirne la de-anonimizzazione.
              Nella misura richiesta, l’Utente concede a WITTUR una licenza
              irrevocabile, mondiale, non esclusiva, esente da royalty e
              sublicenziabile per utilizzare tali dati. Gli scopi commerciali
              includono, a titolo esemplificativo ma non esaustivo, lo sviluppo,
              la produzione, il miglioramento e/o la commercializzazione di
              prodotti e servizi.
            </>,
          ],
        },
        {
          title: 'VIII. Durata e Risoluzione',
          content: [
            <>
              L’Accordo ha una durata illimitata e l’Utente ha il diritto di
              risolvere l’Accordo in qualsiasi momento per comodità. L’Utente ha
              preso atto ed è consapevole (i) che la funzionalità dell’App sarà
              limitata o sospesa nel momento in cui scadrà il periodo di
              abbonamento e/o i diritti di utilizzo della ElevatorSense Box ai
              sensi del Contratto Box e (ii) che la risoluzione per comodità
              dell’Accordo da parte dell’Utente non inciderà sugli obblighi che
              potrebbero ancora derivare dal Contratto Box.
            </>,
            <>
              Salvo diverso accordo con l’Utente, le licenze per le Funzionalità
              acquistate dall’Utente sono valide fino alla fine del relativo
              periodo per tale Funzionalità. Se l’Utente non risolve la licenza
              per una Funzionalità tre (3) mesi prima della fine del periodo, il
              periodo sarà automaticamente prorogato di dodici (12) mesi e
              l’Utente sarà tenuto a pagare gli oneri aggiuntivi concordati per
              tale periodo. Il diritto di risolvere una licenza per una
              Funzionalità con effetto immediato per giusta causa rimane
              invariato. La Sezione VIII.1 si applica mutatis mutandis alle
              Funzionalità.
            </>,
            <>
              Alla risoluzione dell’Accordo, l’Utente dovrà (i) cessare
              immediatamente l’uso dell’App e riconosce che WITTUR può bloccare
              l’accesso dell’Utente e del suo Personale Autorizzato, e (ii)
              immediatamente, ma non oltre 5 (cinque) giorni, eliminare e
              distruggere tutti i contenuti scaricati e il materiale stampato.
              L’Utente non ha alcun diritto, titolo o interesse (né diritti
              d’autore, marchi o altri diritti di proprietà intellettuale)
              sull’App o su qualsiasi contenuto, informazione, materiale,
              applicazione o altra funzionalità ad essa correlata.
            </>,
          ],
        },
        {
          title: 'IX. Diritti in Caso di Difetti',
          content: [
            <>
              L’App sarà fornita e mantenuta in uno stato idoneo all’uso
              contrattuale. L’obbligo di manutenzione non include l’adattamento
              delle funzionalità dell’App stessa a condizioni operative
              modificate e a sviluppi tecnici e funzionali non correlati alla
              ElevatorSense Box, l’adattamento all’ambito delle funzioni di
              prodotti concorrenti o la creazione di compatibilità con nuove
              versioni del browser.
            </>,
            {
              text: (
                <>
                  WITTUR garantisce che l’App è priva di diritti di terzi, in
                  particolare diritti di proprietà intellettuale, che ne
                  limitino o escludano l’uso secondo i presenti Termini di
                  Utilizzo. In caso di qualsiasi affermazione secondo cui l’uso
                  dell’App, come autorizzato in base ai presenti Termini di
                  Utilizzo, viola o lede brevetti, diritti d’autore, marchi o
                  altri diritti di proprietà intellettuale di terzi (
                  <strong>"Violazione"</strong>)
                </>
              ),
              list: [
                <>
                  WITTUR ha il diritto di modificare l’App affinché la
                  Violazione non si applichi più oppure di ottenere
                  l’autorizzazione per l’uso dell’App in conformità con i
                  Termini di Utilizzo, senza limitazioni e senza costi
                  aggiuntivi per l’Utente;
                </>,
                <>
                  l’Utente si impegna a fornire a WITTUR una notifica immediata
                  in forma scritta (ad es. via e-mail) e tutte le informazioni
                  in relazione alla Violazione; e
                </>,
                <>
                  l’Utente coopererà e fornirà assistenza a WITTUR per porre
                  fine alla Violazione, nella misura ragionevolmente attesa.
                </>,
              ],
            },
            'Il diritto a richiedere danni è soggetto alle limitazioni di responsabilità ai sensi della successiva Sezione X..',
          ],
        },
        {
          title: 'X. Limitazione di Responsabilità',
          content: [
            'WITTUR è responsabile senza limitazioni per i danni causati da dolo o colpa grave di WITTUR o dei suoi ausiliari, per danni causati da lesioni alla vita, al corpo o alla salute, nonché nell’ambito delle leggi applicabili sulla responsabilità del prodotto.',
            <>
              Per danni e spese causati da semplice negligenza, WITTUR è
              responsabile solo per i danni tipicamente prevedibili se tali
              danni e spese sono stati causati dalla violazione, da parte di
              WITTUR, di obblighi contrattuali essenziali. Gli obblighi
              contrattuali sono essenziali se il loro adempimento è necessario
              per raggiungere lo scopo dell’Accordo e l’Utente può pertanto fare
              affidamento sul loro adempimento.
            </>,
            'Per il resto, WITTUR non assume alcuna responsabilità.',
            <>
              Per evitare qualsiasi dubbio, WITTUR non sarà in nessun caso
              responsabile per danni o perdite se e nella misura in cui tali
              danni o perdite derivino dal mancato rispetto, da parte
              dell’Utente, di raccomandazioni o avvisi forniti o contenuti
              nell’App.
            </>,
          ],
        },
        {
          title: 'XI. Limitazioni di Responsabilità per i Contenuti Forniti',
          content: [
            <>
              Non sussiste alcuna responsabilità per le informazioni fornite nei
              Servizi e per eventuali Raccomandazioni fornite nell’App (
              <strong>"Contenuti Forniti"</strong>) da WITTUR. Come risulta
              dalla Sezione VII.2, WITTUR non sarà responsabile per l’uso dei
              Contenuti Forniti da parte dei clienti dell’Utente o per essi.
            </>,
            <>
              Nel fornire informazioni nei Servizi, tali informazioni potrebbero
              non essere accurate, poiché i dati generati dalla ElevatorSense
              Box sono soggetti a condizioni esterne.
            </>,
            <>
              Nel fornire Raccomandazioni, l’App fornisce determinate
              probabilità per un determinato risultato e raccomanda le azioni di
              conseguenza. Tuttavia, l’App non fornisce alcuna valutazione
              conclusiva. Effettuare una valutazione appropriata, compreso
              l’acquisto di prodotti ElevatorSense nel web shop di WITTUR come
              sostituzione, rimane la responsabilità unica ed esclusiva
              dell’Utente.
            </>,
            {
              text: 'L’Utente deve tenere presente le seguenti limitazioni che si applicano ai Contenuti Forniti generati da questa App:',
              list: [
                <>
                  I Contenuti Forniti sono generati utilizzando algoritmi
                  altamente complessi. Tuttavia, è ovviamente impossibile per un
                  algoritmo modellare ogni singola caratteristica di un evento
                  individuale. L’App fornisce quindi un’approssimazione in
                  continuo miglioramento per supportare decisioni
                  personalizzate.
                </>,
                <>
                  Nuovi dati e dati aggiornati, che costituiscono la base dei
                  Contenuti Forniti, vengono costantemente inseriti
                  nell’algoritmo. WITTUR raccoglie questi dati al meglio della
                  sua conoscenza con la dovuta diligenza professionale.
                  Tuttavia, non può essere fornita alcuna garanzia sulla
                  correttezza, completezza e attualità di tali dati. Errori in
                  un set di dati potrebbero portare a Contenuti Forniti
                  inaffidabili.
                </>,
              ],
            },
          ],
        },
        {
          title: 'XII. Protezione dei Dati',
          content: [
            <>
              Poiché la protezione dei dati è una priorità per WITTUR, WITTUR
              rispetta rigorosamente le leggi applicabili in materia di
              protezione dei dati durante il trattamento (ad es. raccolta,
              utilizzo, divulgazione, ecc.) dei dati personali. Se e nella
              misura in cui non viene fornita un’informazione diversa sul
              trattamento specifico dei dati personali da parte di WITTUR, le
              informazioni in merito a tale trattamento sono incluse nella
              politica sulla privacy applicabile, accessibile nell’App.
            </>,
            'L’Utente deve rispettare le leggi applicabili in materia di protezione dei dati quando fornisce dati personali a WITTUR, ad esempio tramite l’App.',
          ],
        },
        {
          title: 'XIII. Modifiche / Trasferimento del Contratto',
          content: [
            <>
              WITTUR ha il diritto di modificare i presenti Termini di Utilizzo
              in qualsiasi momento. L’Utente sarà informato di tali modifiche
              con almeno sessanta (60) giorni di calendario di preavviso
              rispetto alla prevista implementazione delle modifiche. Se
              l’Utente non si oppone entro trenta (30) giorni di calendario
              dalla ricezione della notifica e continua a utilizzare l’App dopo
              la scadenza del periodo di opposizione, le modifiche si
              considereranno validamente concordate a partire dalla scadenza di
              tale periodo. In tale notifica, l’Utente sarà informato del suo
              diritto di opposizione e delle sue conseguenze.
            </>,
            <>
              WITTUR si riserva il diritto di trasferire il presente Contratto
              con l’Utente a un’altra società. Gli Utenti saranno informati di
              tale trasferimento e potranno recedere dal Contratto entro dieci
              (10) giorni di calendario dal momento in cui hanno ricevuto
              l’informazione sul trasferimento.
            </>,
          ],
        },
        {
          title: 'XIV. Varie',
          content: [
            <>
              Qualora una o più disposizioni dei presenti Termini di Utilizzo
              fossero invalide o non applicabili a causa di violazioni della
              legge applicabile o per altri motivi, le restanti disposizioni
              rimarranno comunque valide. La disposizione invalida o non
              applicabile sarà sostituita da una disposizione valida e
              applicabile, che rifletta il più fedelmente possibile la comune
              volontà delle parti.
            </>,
            <>
              La legge che disciplina la validità, l’interpretazione e
              l’esecuzione dei presenti Termini di Utilizzo è la legge tedesca,
              con l’esclusione delle sue norme sul conflitto di leggi, e i
              tribunali competenti per ogni controversia derivante da o
              correlata ai presenti Termini di Utilizzo sono i tribunali di
              Monaco di Baviera.
            </>,
          ],
        },
        {
          title: 'XV. Utenti Internazionali',
          content: [
            <>
              Questa App è gestita, monitorata e aggiornata da WITTUR GmbH. È
              destinata esclusivamente all’uso nei paesi elencati nell’
              <a href="#appendix_1">Appendice 1</a>. Qualora l’Utente acceda a
              questa App e/o scarichi contenuti da fuori di tali paesi, l’Utente
              è il solo ed esclusivo responsabile di assicurare che tale
              utilizzo sia conforme alle relative leggi locali.
            </>,
          ],
        },
      ],
    },
    appendix: {
      title:
        'Appendice 1: PAESE DI DISTRIBUZIONE E CONDIZIONI GIURISDIZIONALI LOCALI',
      content: [
        {
          heading:
            'Condizioni Aggiuntive o Divergenti per il Paese di Distribuzione',
          details: [
            {
              country: 'India',
              content: [
                'La Sezione V. (Account Utente) è integrata come segue:',
                <>
                  L’Utente acconsente che WITTUR o le sue Affiliate raccolgano
                  la sua password allo scopo di consentire l’accesso e
                  l’utilizzo dell’App. La password può essere trattata da WITTUR
                  o dalle sue Affiliate in conformità con l’Informativa sulla
                  Privacy.
                </>,
                <>
                  La Sezione X.2. (Limitazione di Responsabilità) è modificata
                  come segue: Per danni e spese causati da un inadempimento
                  materiale di WITTUR, WITTUR sarà responsabile solo per i danni
                  che sorgono naturalmente nel normale corso di tale
                  inadempimento, o di cui l’Utente era a conoscenza, al momento
                  dell’accettazione dei Termini di Utilizzo, che sarebbero
                  probabilmente derivati da detto inadempimento. (i) Ciò non
                  include danni derivanti da perdite o danni remoti e indiretti
                  subiti a causa dell’inadempimento materiale e (ii) è limitato
                  al corrispettivo ricevuto dall’Utente per la ElevatorSense
                  Solution, che l’Utente e WITTUR concordano rappresenti una
                  stima preliminare equa, ragionevole e autentica di eventuali
                  danni derivanti da un inadempimento materiale.
                </>,
              ],
            },
            {
              country: 'Regno Unito',
              content: [
                <>
                  La Sezione X.2. (Limitazione di Responsabilità) è modificata
                  come segue:
                  <br /> WITTUR è responsabile solo per i danni e le spese
                  tipicamente prevedibili se tali danni e spese sono stati
                  causati da un inadempimento materiale di WITTUR, che WITTUR
                  non abbia rimediato entro trenta (30) giorni dalla ricezione
                  della notifica della violazione da parte del Cliente. Fatto
                  salvo quanto sopra, WITTUR esclude ogni altra responsabilità
                  (sia diretta che indiretta, indipendentemente dalla causa) ai
                  sensi dei presenti Termini di Utilizzo.
                </>,
              ],
            },
          ],
        },
      ],
    },
  },
  testCompleted: 'Test completato.',
  testResults: 'Risultati del test',
  time: 'Ora',
  tooHigh: 'Troppo alto',
  tooLoose: 'Troppo allentato',
  tooLow: 'Troppo basso',
  tooTight: 'Troppo stretto',
  top: 'in alto',
  topToBottom: "Dall'alto al basso",
  touching: 'in contatto',
  transformer: 'Trasformatore',
  truckRoller: 'Rotella carrello',
  truckRollerInferior: 'Rotella inferiore carrello',
  truckRollerSuperior: 'Rotella superiore carrello',
  twisted_cd_panels: 'Pannelli porta di piano svergolati',
  twisted_ld_panels: 'Pannelli svergolati',
  unlinkBox: 'Scollega il box',
  unreadNotifications: (n: number) => `${n} notifiche non lette`,
  unshare: 'Smetti di condividere',
  update: 'Aggiornamento',
  updateRequired: 'Aggiornamento richiesto',
  updateStaticData: 'Aggiorna i dati',
  updating: 'Aggiornamento...',
  upToDate: 'Aggiornato',
  usage: 'Utilizzo',
  usedElectronics: 'Ascensori utilizzati',
  validFrom: 'Valido dal',
  validUntil: 'Valido fino al',
  vibration: 'Vibrazione',
  viewErrorLog: 'Visualizza registro errori',
  waitWhileInitializingTest: "Attendere l'inizializzazione del test ...",
  waitingForData: 'Nuovi dati in arrivo',
  waitingForTestResults: 'In attesa dei risultati del test ...',
  warning: 'Avviso',
  warnings: 'Avvisi',
  warrantyExtensionRequestedOn: (date: string) =>
    `L'estensione della garanzia è stata richiesta in ${date}`,
  whyNotReplaced: 'Perché il componente non è stato sostituito?',
  wifiScanned: 'Il WIFI è stato scansionato',
  years: 'anni',
  yes: 'Sì',
  zero_position: 'Posizione zero',
  zero_position_description: 'La posizione zero non è corretta.',
  zero_position_eco_actions:
    "Procedura ECO+:\n    1) Imposta S1DIP1 su ON (modalità di servizio).\n    2) Chiudi la porta manualmente (posizione zero).\n    3) Premi il pulsante di apprendimento > 10 s (Longlearn).\n    4) Apri e chiudi la porta utilizzando il pulsante di azionamento di servizio.\n    5) Tieni premuti i pulsanti all'estremità di apertura della porta e all'estremità di chiusura della porta per > 2 s.\n    Risultato: il LED di stato deve spegnersi dopo 2 cicli.\n    ",
  zero_position_midi_actions:
    'Procedura MIDI/SUPRAV2:\n    Chiudi la porta manualmente (posizione zero).\n    Segui le istruzioni del pittogramma.',
  yourBuilding: 'Il tuo edificio',
  yourBuildings: 'I tuoi edifici',
};
