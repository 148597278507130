import React from 'react';

import { Translations } from './en';

export const es: Translations = {
  aborted: 'Interrumpido',
  accelerationDescription:
    'Factor de aceleración que se utiliza para el cálculo de la curva.',
  accelerationNote:
    'Este factor tiene que ser lo suficientemente alto para alcanzar las velocidades ajustadas, de lo contrario la puerta no alcanzará la velocidad deseada.',
  accelerationTitle: 'Aceleración',
  acceptTerms: 'Acepto las condiciones de uso',
  add: 'Añadir',
  addBuilding: 'Añadir edificio',
  addLift: 'Añadir Ascensor',
  addressHint:
    'Introduce el nombre de una empresa o edificio para recibir sugerencias de direcciones e imágenes.',
  adjustCameraSettings:
    'También puede ajustar la configuración de la cámara en el menú desplegable.',
  admin: 'Admin',
  alreadyHasSubcontractorsError:
    'Como subcontratista, no puede tener subcontratistas. Por favor, elimine los subcontratistas existentes antes de aceptar esta invitación.',
  all: 'Todo',
  allBuildings: 'Todos los edificios',
  allBuildingsSubscription: 'De todos los edificios',
  alphabetical: 'Alfabético',
  ambientTemperature: 'Temperatura de la caja',
  assetNumber: 'ID ascensor',
  assetNumberHint:
    'Introduce el ID (número aka instalación/equipamiento) del ascensor si lo tienes a mano.',
  auto: 'Auto',
  back: 'Atrás',
  backToDashboard: 'Volver al Dashboard',
  belt_tension: 'Tensión de la correa',
  belt_tension_actions:
    'Ajusta la tensión de la correa como se describe en el manual de instalación.',
  beltclamp: 'Abrazadera',
  belt_tension_description: 'La tensión de la correa es demasiado alta/baja.',
  betweenDoorCycle: 'Entre el ciclo de la puerta',
  bottom: 'abajo',
  bottomToTop: 'Abajo hacia arriba',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `La caja está vinculada al ${lift} en el ${building}. ¿Continuar?`,
  boxNotReady: 'La caja seleccionada no está lista.',
  boxOfflineHint:
    'Si desea actualizar una de las cajas que se enumeran a continuación, primero conéctelo a Internet.',
  boxScannedNoIdFound: 'Caja escaneada, pero no se encontró ningún ID.',
  boxSoftware: 'Software',
  boxSoftwareStatusError:
    'No se pudo recuperar el estado de las cajas: por favor, revise la conexión a internet de la caja e inténtelo de nuevo. Si el problema persiste, póngase en contacto con su representante de Wittur.',
  boxSoftwareTitle: 'Software de Caja',
  boxSoftwareUpdateError:
    'Error al actualizar el software de la caja: por favor, revise la conexión a internet de la caja e inténtelo de nuevo. Si el problema persiste, póngase en contacto con su representante de Wittur.',
  box_id: 'ID caja',
  brakeParDescription:
    'Mediante el ajuste de este parámetro se influye en el retardo y el arrastre al final de apertura/cierre (preajustado en ‘5’).',
  brakeParNote:
    '"El rango de ajuste posible es de ""0"" a ""9"", pero el freno más rápido se alcanza con el parámetro ""0"" y el retraso más lento se alcanza con el parámetro ""9""."',
  brakeParTitle: 'Parámetro de freno',
  buffer: 'Buffer',
  building: 'Edificio',
  buildingHasNoElevator: 'Este edificio no tiene ascensores.',
  buildingName: 'Nombre',
  buildings: 'Edificios',
  buildingsWithoutValidAddress: 'Edificios sin dirección válida',
  bushingClose: 'Casquillo cerrado',
  bushingOpen: 'Casquillo abierto',
  cablechain: 'Guiado de cables',
  cableTensorSpring: 'Muelle tensor del cable',
  callFloorsInstruction: (
    <>
      {' '}
      Abre la puerta y <b>llama todos los demás pisos</b>. Sal de la cabina y
      haz clic en ""Continuar"".
    </>
  ),
  cameraMode: 'Modo de cámara',
  cancel: 'Cancelar',
  car: 'Cabina',
  carDoor: 'Puerta de la cabina del ascensor',
  carDoorCounter: 'Contador de ciclos de la electrónica de puerta anterior',
  carDoorModified: 'Se ha modificado la puerta de la cabina del ascensor',
  cd_counter_rollers_installation: 'Ruedas inferiores',
  cd_counter_rollers_installation_actions:
    '"""Para cada puerta de piso, comprueba que las ruedas se pueden girar fácilmente con la mano y que no estén a demasiada distancia de la guía. Si así fuera, afloja las ruedass y vuelve a ajustarlos para que estén lo más cerca posible de la guía, pero sin tocarla, y puedan girarse fácilmente con la mano. Si necesitas desmontar el patin para acceder a las ruedas, sigue las instrucciones de montaje."""',
  cd_counter_rollers_installation_description:
    'Las ruedas inferiores de la puerta de cabina no están instaladas correctamente.',
  cd_roller: 'Rueda puerta de cabina',
  chooseFloorOrCardoor: 'Elija Piso / Puerta de cabina',
  choosePartNumber: 'Elija el número de pieza',
  chooseReason: 'Elige un motivo (opcional)',
  chooseStartingFloorInstructions:
    'Seleccione el piso de inicio de la marcha de control. El piso de inicio puede ser el más alto o el más bajo.',
  clearDriveErrors: 'Borrar',
  close: 'Cerrar',
  closeDoorInstructions: (floor: string) => (
    <>
      {' '}
      Ve al piso <b>{floor}</b>. Antes de proceder, comprueba que la puerta de
      la cabina del ascensor esté cerrada.
    </>
  ),
  closeForcePotiDescription:
    'Establece el límite de fuerza utilizado en la apertura y cierre de la puerta.',
  closeForcePotiNote:
    'Ajustable mediante potenciómetro en la electrónica. En las nuevas electrónicas como MIDIV2 ajustar aquí en modo de servicio si es necesario (no hay necesidad de ajustar en aplicaciones estándar debido al autoaprendizaje).',
  closeForcePotiTitle: 'Límite de fuerza de cierre',
  closeHoldForceDescription: 'Fuerza que se aplica en el extremo cercano.',
  closeHoldForceNote:
    'Esta fuerza debe ser de entre 50N y 70N. Ten en cuenta que una fuerza demasiado baja provocará aperturas de los acopladores no deseada. Una fuerza demasiado alta calentará el motor innecesariamente y puede que funcione en modo de reducción.',
  closeHoldForceTitle: 'Fuerza de cierre final (retención)',
  closeSpeedDescription:
    'Velocidad máxima de la puerta en sentido de cierre. La curva cerrada se calcula para alcanzar esta velocidad ajustada, si es posible.',
  closeSpeedNote:
    'La velocidad real de la puerta también está relacionada con los interruptores DIP de velocidad de los accionamientos. El ajuste a velocidad 4 significa el 100% del valor 2.',
  closeSpeedTitle: 'Velocidad de cierre',
  closing_device_failure: 'Dispositivo de cierre',
  closing_device_failure_actions:
    '"""Comprueba que todos los componentes del dispositivo de cierre de la puerta del piso funcionen correctamente (spirator, muelle de cierre o contrapeso). La puerta del piso debe poder cerrarse sola desde cualquier posición."""',
  closing_device_failure_description:
    'El dispositivo de cierre no está instalado correctamente. Se requiere demasiada fuerza para cerrar la puerta.',
  companyName: 'Nombre de la empresa',
  completed: 'Terminado',
  componentDetails: 'Detalles del componente',
  componentDetailsHint:
    'Para algunos componentes, hay varias opciones de números de pieza que deben seleccionarse. Esta es información importante para usar la función de mantenimiento preventivo. Haga clic en los elementos enumerados para seleccionar la pieza real instalada.',
  confirm: 'Confirmar',
  confirmDeleteBuilding: '¿De verdad quieres borrar este edificio?',
  confirmDeleteErrorHistory:
    '¿Realmente desea eliminar este historial de errores?',
  confirmDeleteFavoriteBuilding:
    '¿De verdad quieres eliminar este edificio de tus favoritos?',
  confirmDeleteLift: '¿De verdad quieres borrar este ascensor?',
  confirmInvite: 'Confirmar y Conectar',
  connectionErrorMessage:
    'No se pueden obtener datos debido a un problema de conexión. Por favor, inténtelo de nuevo más tarde.',
  continue: 'Continuar',
  contractorBuilding: 'Edificio del contratista',
  contractorBuildings: 'Edificios del contratista',
  contractors: 'Contratistas',
  couplerelease: 'Apertura de patin',
  couplerFriction: 'Fricción del patín',
  couplerMovementParameter: 'Parámetro movimiento del patín',
  couplerSpeedDescription:
    'Este valor define la velocidad máxima de la correa, en la zona de acoplador definida.',
  couplerSpeedTitle: 'Velocidad del patín',
  couplerWitdhDescription:
    'Este valor define la distancia de la correa en la zona del patín. Este valor se obtiene tras el procedimiento de aprendizaje.',
  couplerWitdhTitle: 'Ancho del patin',
  currentVersion: 'Version actual',
  couplerwidth: 'Ancho patín',
  date: 'Fecha',
  delete: 'Borrar',
  deleteErrorHistory: 'Eliminar el historial de errores',
  directConnection: (
    <>
      <p>
        {' '}
        Conéctate al WIFI de la caja ELEVATORSENSE escaneando el código QR
        impreso en la caja con la cámara de tu teléfono.
      </p>
      <p>
        {' '}
        Una vez conectado, vuelve a esta pantalla y pulsa el botón{' '}
        <b>continuar</b> de abajo.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Desconectar la programación inalámbrica',
  divpulley: 'Divpulley',
  documentation: 'Documentación',
  doorContact: 'Contacto puerta',
  doorCycleCounter: 'Contador ciclos puerta',
  doorCycleError1: 'No se han recibido datos del ciclo de puerta.',
  doorCycleError2: 'Se interrumpió el movimiento del ciclo de la puerta.',
  doorCycleError4: 'Se ha detectado una reapertura de la puerta.',
  doorCycleError5: 'Se ha detectado un nuevo cierre de la puerta.',
  doorCycleError7: 'No se han recibido ciclos de puerta válidos.',
  doorCycles: 'Ciclos de puerta',
  doorFriction: 'Fricción de la puerta',
  doorInstallationDate: 'Fecha de instalación de la puerta',
  doorIsClosed: 'Sí, la puerta está cerrada',
  doorModel: 'Modelo de puerta',
  doorModelNotSupported:
    'El modelo de puerta seleccionado no está disponible para el mantenimiento preventivo.',
  doorMovementParameter: 'Parámetro movimiento de la puerta',
  doorPosition: 'Posición de la puerta',
  doorSerialNumber: 'Número de serie de la puerta',
  doorType: 'Tipo de puerta',
  doorwidth: 'Ancho puerta',
  downloadCertificate: 'Descargar certificado',
  downloadLicense: 'Descargar todas las licencias como CSV',
  downloadRequestedExtendedWarranty:
    'Descargar todas las solicitudes de ampliación de garantía',
  drive_motor_noise: 'Ruido motor accionamiento',
  driveesn: 'ESN',
  drivesoftware: 'Firmware',
  duringLiftMovement: 'Durante el movimiento del ascensor',
  editBuilding: 'Editar edificio',
  editLift: 'Editar ascensor',
  elecBoard: 'Tarjeta electrónica',
  elevatorIdNotFound: (
    <>
      Para solicitar una garantía ampliada, debe introducirse el número de serie
      de la puerta en los ajustes del ascensor. ajustes del ascensor.
      <b>Haga clic aquí para acceder a los ajustes.</b>
    </>
  ),
  elevatorInstallationDate: 'Fecha de instalación del ascensor',
  elevatorUsageType: 'Tipo de uso del ascensor',
  email: 'Email',
  encoderTemperature: 'Temperatura del encoder',
  error: 'Error',
  errorDescription: 'Descripción del error',
  errorEarlierThanInstallationDate:
    'No es posible ingresar una fecha anterior a la fecha de instalación.',
  errorInvalid: 'Introduce un valor válido.',
  errorInvalidOperation: 'Operación no válida',
  errorMessages: 'Mensajes de error',
  errorMessagesDescription: 'Pantalla de los mensajes de error registrados.',
  errorNotFound: 'Recurso no encontrado',
  errorRetryCamera:
    'No se puede acceder a la cámara. Reintentando, la página se recargará...',
  errorRequired: 'Este campo es obligatorio.',
  errorUnableToAccessCamera:
    'No se puede acceder a la cámara después de varios intentos. Por favor, actualice la página o revise los permisos de la cámara.',
  errorUnknownOrForbiddenBox:
    'La caja ElevatorSense no está disponible. Ponte en contacto con tu servicio Wittur si necesitas asistencia.',
  errors: 'Errores',
  errorsEmpty: 'Sin mensajes.',
  errorsEmptyHint:
    'Por favor, comprueba tus filtros y, si procede, habilita los tipos adicionales.',
  extendWarranty: 'Ampliación de garantía',
  fatalError: 'Error fatal',
  fatalErrors: 'Errores fatales',
  favorite: 'Favorito',
  favorites: 'Favoritos',
  figureAdjustment: 'Ajuste',
  figureAugustaEVO: 'Ejemplo Augusta EVO',
  figureClosingWeight: 'Contrapeso',
  figureEco: 'ECO+',
  figureHydra: 'Ejemplo Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'No OK',
  figureOk: 'OK',
  figureSGV: 'Ejemplo SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Muelle de cierre',
  firstName: 'Nombre',
  floor: 'Piso',
  floors: 'Pisos',
  followingBoxScanned: 'La caja siguiente ha sido escaneada',
  forgotPassword: '¿Has olvidado tu contraseña?',
  forgotPasswordText:
    'Introduce tu dirección de email para restablecer la contraseña.',
  groundFloor: 'Planta baja',
  home: 'Inicio',
  incompleteElevatorSettings:
    'Para utilizar el mantenimiento preventivo, necesitamos más información sobre este ascensor. Vaya a la configuración de su ascensor y complete la información que falta.',
  initialized: 'Inicializado',
  installationControl: 'Installation Control',
  installationControlDescription:
    'Realizar las comprobaciones automáticas para detectar problemas comunes de instalación.',
  installationControlExecutedBy: 'Ejecutado por',
  installationControlHeadline: 'Estado de la última instalación',
  installationControlHistory: 'Historial de viajes',
  installationControlHistoryEmpty:
    'No se ha registrado ningún viaje de la instalación',
  installationControlInstructions: 'Mostrar instrucciones',
  installationControlSubheadline: 'Resumen de todos los viajes',
  installationDateErrorMessage:
    'No es posible ingresar una fecha de instalación de la puerta anterior a la fecha de instalación del ascensor',
  instantSubscription: 'Notificación instantánea',
  instantSubscriptionExplanation: (
    <>
      <b>Notificación instantánea</b> es una notificación inmediata por correo
      electrónico en cuanto se produce un error fatal en un ascensor. Los
      errores fatales son errores que pueden provocar el fallo inmediato del
      ascensor.
    </>
  ),
  invitationExpired: 'El enlace de la invitación ya no es válido.',
  inviteAsManager: 'El usuario puede invitar a otros usuarios.',
  inviteCompanyWithSubcontractorsError:
    'La empresa que intenta invitar ya tiene subcontratistas y no puede ser añadida como subcontratista. Deben eliminar los subcontratistas existentes o elegir otra empresa.',
  inviteCoworkers: 'Invitar a compañeros de trabajo',
  inviteSubcontractor: 'Invitar a un subcontratista',
  inviteToSignUpMessage: (companyName: string) =>
    `La empresa ${companyName} lo ha invitado a registrarse en ElevatorSense como su subcontratista. Por favor, complete el formulario para registrarse.`,
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} le ha invitado a ser su subcontratista en ElevatorSense.`,
  ipaddress: 'Dirección IP',
  joinText: (name: string) => (
    <>
      Regístrate para unirte a de la organización <i>{name}</i>.
    </>
  ),
  landingDoor: 'Puerta de piso - Piso',
  landing_lock_roller_adjustment: 'Ajuste de la rueda de la cerradura de piso',
  landingCarDoorSpareParts: 'Piezas de repuesto para puertas de cabina/rellano',
  lastError: 'Último Error',
  lastExchangeDate: 'Última fecha de intercambio',
  lastMaintenanceDate: 'Última fecha de mantenimiento',
  lastMaintenanceScope: 'Alcance del último mantenimiento',
  lastMaintenanceScopeHint:
    'Seleccione el alcance del mantenimiento recomendado por Wittur que se realizó realmente en la última fecha de mantenimiento',
  lastName: 'Apellido',
  lastTimeDoorElectronicsChange:
    'Fecha de sustitución de la electrónica de puerta',
  latestSoftwarePackVersion: (version: string) =>
    `Versión del paquete de software: ${version}`,
  ld_counter_rollers_installation: 'Ruedas inferiores',
  ld_counter_rollers_installation_actions:
    '"""Para cada puerta de piso, comprueba que las ruedas se pueden girar fácilmente con la mano y que no estén a demasiada distancia de la guía. Si así fuera, afloja las ruedas y vuelve a ajustarlos para que estén lo más cerca posible de la guía, pero sin tocarla, y puedan girarse fácilmente con la mano. Si necesitas desmontar el acoplador para acceder a las ruedas, sigue las instrucciones de montaje."""',
  ld_counter_rollers_installation_description:
    'Las ruedas inferiores de la puerta de piso no están instaladas correctamente.',
  ld_roller: 'Rueda de puerta de piso',
  left: 'Izquierda',
  licenseRequired:
    'No tienes una licencia válida para este producto. Ponte en contacto con tu comercial Wittur para comprar este producto ElevatorSense.',
  lift: 'Ascensor',
  liftMovementDistance: 'Movimiento del ascensor',
  liftSetup: 'Configuración del ascensor',
  liftSetupComplete: 'Configuración completada.',
  liftSetupError: 'Faltan los datos correspondientes o están incompletos.',
  liftSetupError40:
    'El movimiento del ascensor no coincide con la marcha configurada.',
  liftSetupIncomplete: 'Configuración incompleta.',
  liftSetupPending:
    'Realice la configuración inicial de ElevatorSense en su ascensor. Sin la ejecución de la configuración no podrá utilizar algunas funciones de los productos ElevatorSense o los resultados de los productos ElevatorSense pueden diferir. Haga clic aquí para realizar la configuración.',
  liftSetupRepeat:
    'La marcha de configuración no se ha realizado con éxito. Vuelve a realizar la marcha teniendo en cuenta la descripción del proceso.',
  lifts: 'Ascensores',
  linkBox: 'Enlazar caja',
  linkToWebshop: (link: string) => (
    <>
      Si tiene una caja ElevatorSense y desea activar una licencia, haga clic{' '}
      <a href={link} className="clickable-link">
        aquí
      </a>
      .
    </>
  ),
  loading: 'Cargando',
  login: 'Acceder',
  loginText: 'Accede a tu cuenta.',
  logout: 'Salir',
  mandatory: 'Realizar Comprobación de Sustitución',
  manageAccess: 'Administrar Acceso',
  manageElevators: 'Gestionar ascensores',
  manageSharedBuildingsAndElevator:
    'Gestionar edificios/ascensores compartidos',
  manual: 'Manual',
  maxElectronics: 'Máx. ascensores',
  maxUsage: 'Límite de uso',
  measured: 'Medido',
  members: 'Miembros',
  misalignment_cd_vs_ld: 'Alineación de puerta/patin',
  misalignment_cd_vs_ld_actions:
    'Regula las ruedas de la cerradura de la puerta de piso. El juego entre el patín y las ruedas debe ser el mismo en el lado izquierdo y en el derecho.',
  misalignment_cd_vs_ld_description:
    'El patín de la puerta de la cabina no está alineado con las ruedas de la cerradura de la puerta de piso.',
  misalignment_cd_vs_ld_sill_gap: 'Distnacia entre pisaderas',
  misalignment_cd_vs_ld_sill_gap_actions:
    'La distancia entre pisaderas en el extremo izquierdo y en el extremo derecho del hueco de la puerta no es igual. Si esto ocurre en todos los pisos, ajusta la posición de la pisadera de la puerta de la cabina del ascensor.',
  misalignment_cd_vs_ld_sill_gap_description:
    'La puerta de piso no está paralela a la puerta de la cabina.',
  mode: 'Modo',
  monitoring: 'Vigilancia',
  motor: 'Motor',
  motorTemperature: 'Temperatura del motor',
  myBuildingsSubscription: 'Solo de mis favoritos',
  name: 'Nombre',
  networkQuality: 'Calidad de la red',
  newInstallationControl: 'Nuevo control de instalación',
  next: 'Sig.',
  nextMaintenanceDate: 'Próxima fecha de mantenimiento',
  no: 'No',
  notAuthorizedToConfirmInviteError:
    'No está autorizado para confirmar esta invitación, ya que no está destinada a su usuario conectado.',
  noBoxLinked:
    'El ascensor aún no está vinculado a una caja ELEVATORSENSE. Para utilizar todas las funciones de la aplicación, escanea el código QR de la caja o introduce manualmente el identificador de la caja.',
  noBuildingsVisible:
    'No hay edificios visibles en la vista actual del mapa. Intenta ajustar el mapa para ver más edificios.',
  noComponentsFound: 'No se encontraron componentes para este piso',
  noFavorite: 'No es favorito',
  noFavoritesChoosen:
    'Aún no has seleccionado ningún edificio favorito. Navega hasta tu edificio y haz clic en el icono de la estrella para añadirlo a tus edificios favoritos.',
  noFloorInfoAvailable: 'No hay información de pisos disponible',
  noInstallationControlFound:
    'El ESN de la electrónica de la puerta no coincide con el ESN de la última ejecución de la Installation Control. Compruebe si está conectado al ascensor correcto.',
  noSubcontractors: 'No se encontraron subcontratistas.',
  noSubcontractorMessage:
    'Actualmente no hay subcontratistas vinculados a su empresa. Para establecer uno, envíe una invitación.',
  noSubscription: 'Ninguno',
  note: 'Aviso',
  noQRCodeDetected:
    'Aún no se detecta el código QR. Por favor, mueve la cámara para que el código QR esté enfocado y completamente visible en la vista.',
  nudgingSpeedDescription:
    'Velocidad de la puerta en condición de nudging. La curva de cierre/apertura se calcula para alcanzar esta velocidad ajustada si el nudging está activo.',
  nudgingSpeedNote:
    'La velocidad real de la puerta también está relacionada con los interruptores DIP de velocidad de los accionamientos. El ajuste a velocidad 4 significa el 100% del valor 3.',
  nudgingSpeedTitle: 'Velocidad de empuje (Nudging)',
  obsolete: 'Obsoleto',
  offline: 'Desconectado',
  offlineConnection:
    'Actualmente estás desconectado, pero puedes utilizar Wireless Programming.',
  ok: 'Mantenimiento reglamentario',
  online: 'En linea',
  openHoldForceDescription: 'Fuerza que se aplica en el extremo abierto.',
  openHoldForceNote:
    'Esta fuerza debe ser de entre 50N y 60N. Ten en cuenta que una fuerza demasiado baja provocará cierres y aperturas no deseados. Una fuerza de apertura final demasiado alta calentará el motor innecesariamente y puede que funcione en modo de reducción.',
  openHoldForceTitle: 'Fuerza de apertura final (retención)',
  openSpeedDescription:
    'Velocidad máxima de la puerta en sentido de apertura. La curva abierta se calcula para alcanzar esta velocidad ajustada, si es posible.',
  openSpeedNote:
    'La velocidad real de la puerta también está relacionada con los interruptores DIP de velocidad de los accionamientos. El ajuste a velocidad 4 significa el 100% del valor 1.',
  openSpeedTitle: 'Velocidad de apertura',
  otherReason: 'Otra razon',
  overdue: 'Atrasado',
  parameter: 'Parámetro',
  partInformation: 'Información del Componente',
  partIsStillOk: 'La parte todavía está bien',
  partNumber: 'Número de Parte',
  partName: 'Nombre de Parte',
  password: 'Contraseña',
  passwordResetSent:
    'Te hemos enviado un email con instrucciones para restablecer la contraseña. Comprueba tu bandeja de entrada.',
  pendingInvites: 'Invitaciones pendientes',
  pendingUserInvites: 'Invitaciones de usuario pendientes',
  pendingSubcontractorInvites: 'Invitaciones de subcontratista pendientes',
  planningAndAlerts: 'Planificación y alertas',
  position: 'Posición',
  positionOutDescription:
    'Introduciendo este parámetro se puede ajustar el relé de posición (relacionado con el FPC) para que conmute en cualquier posición de la puerta. El ajuste estándar es 0 (desactivado).',
  positionOutNote:
    'En caso de ajustar este parámetro a otro valor, el relé conmuta al alcanzar la posición. Esta función se utiliza prácticamente solo en los huecos con cabinas de premovimiento.',
  positionOutTitle: 'Posición de salida',
  postpone: 'Aplazar',
  powerReductionFactor: 'Factor de reducción de potencia',
  predicted: 'Previsto',
  prev: 'Ant.',
  preventiveMaintenance: 'Mantenimiento preventivo',
  preventiveMaintenanceDescription:
    'Reemplace los repuestos de la puerta de su ascensor antes de que se rompan.',
  preventiveMaintenanceUnavailable:
    '¡Los datos introducidos están completos! ElevatorSense calculará ahora el programa de mantenimiento en función del uso de su ascensor. Este proceso puede tardar unos minutos.',
  priority: 'Prioridad',
  product: 'Producto',
  programmingDisabledHint:
    'La programación de la electrónica está actualmente desactivada por el hardware. Asegúrate de que el interruptor DIP 1 esté activado para permitir la programación del dispositivo.',
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'No se pudo abrir la ventana. Asegúrate de que tu navegador no bloquea las ventanas emergentes.',
  programmingErrorRequest:
    'Solicitud fallida. Asegúrate de que tu dispositivo está conectado a la red WIFI de la caja ELEVATORSENSE.',
  programmingTool: 'Wireless Programming',
  programmingToolDescription:
    'Fácil configuración de la electrónica de la puerta.',
  pulley_is_touching_belt: 'Polea',
  pulley_is_touching_belt_actions:
    'Ajusta la alineación de la correa para que no toque los bordes de la brida de la polea.',
  pulley_is_touching_belt_description:
    'La brida de la polea está tocando la correa.',
  readyToRunTest: 'Listo para realizar la prueba.',
  realtimeMonitoringDescription:
    'Muestra los datos de los sensores y los gráficos de movimiento de la puerta en tiempo real.',
  realtimeMonitoringTitle: 'Real Time Diagnostics',
  recommended: 'Planificar revisión de sustitución',
  recommendedActions: 'Acciones recomendadas',
  recommendedDateOfExchange: 'Fecha recomendada de sustitución Comprobación',
  reload: 'Recargar',
  remove: 'Eliminar',
  reopenTimeDescription:
    'El parámetro Reapertura define el tiempo de espera tras el movimiento de reapertura automática en posición final abierta, antes de volver a cerrar la puerta.',
  reopenTimeNote:
    'Esta función se utiliza en caso de procedimiento de reapertura activado por la electrónica de la puerta y no por el controlador principal del ascensor.',
  reopenTimeParameter: 'Parámetro tiempo de reapertura',
  reopenTimeTitle: 'Tiempo de reapertura',
  repeatFloor:
    '¡Repite el proceso de control de la instalación para este piso!',
  repeatWholeRun: (
    <>
      ¡Por favor, repite todo el <b>proceso de</b> control de la instalación!
    </>
  ),
  replaced: 'Reemplazado',
  replacementWasPostponed: 'La sustitución se pospuso el:',
  requestNewLink: 'Solicitar un nuevo enlace',
  resetPassword: 'Restablecer la contraseña',
  resetPasswordText: 'Introduce una nueva contraseña.',
  revoke: 'Revocar',
  right: 'Derecha',
  rollerBumper: 'Rodillo Parachoques',
  rollerSynchronisationRope: 'Cable de sincronización',
  runs: 'Carreras',
  save: 'Guardar',
  scan: 'Escanear',
  scanned: 'Escaneado',
  scanAgain: 'Escanear de nuevo',
  searchPlaces: 'Buscar lugares',
  select: 'Seleccionar',
  selectCameraMode: 'Seleccionar modo de cámara',
  selectDoorModel: 'Seleccionar modelo de puerta',
  selectDoorType: 'Seleccionar tipo de puerta',
  selectModifiedFloors:
    'Selecciona los pisos en los que se han realizado cambios desde la última marcha de prueba. Se han preseleccionado los pisos en los que se produjeron errores durante la última marcha.',
  selectPartsHint:
    'Compruebe qué parte está instalada y haga clic en la imagen correspondiente.',
  send: 'Enviar',
  sendResults: 'Enviar resultados',
  sendTo: '"Enviar resultados a las siguientes direcciones de correo;"',
  sensorData: 'Datos del sensor',
  serviceDriveSwitch: 'Service drive',
  settings: 'Ajustes',
  share: 'Compartir',
  shareBody: (link: string) => `Ver resultado prueba:\n${link}`,
  shareResults: 'Compartir resultados',
  shareSubject: 'Resultado ELEVATORSENSE',
  shoe: 'Deslizadera',
  showPassedTests: 'Mostrar las pruebas superadas...',
  showTerms: 'Ver las condiciones de uso',
  signUp: 'Registrarse',
  skid: 'Patín',
  slideingshoe_failure: 'Error deslizadera',
  slideingshoes_usage: 'Desgaste ',
  somethingWentWrong: 'Algo ha ido mal. Vuelva a intentarlo.',
  sortBy: 'Ordenar por',
  sortByDate: 'Fecha',
  sortByFloor: 'Número de piso',
  sparePartDoesNotFit: 'La pieza de repuesto no encaja',
  sparePartNotAvailable: 'Recambio no disponible',
  speed: 'Velocidad',
  start: 'Iniciar',
  startNewTestRun: 'Nueva marcha de prueba',
  started: 'Ejecutándose',
  starting_floor: 'Piso inicial',
  status: 'Estado',
  subcontractorNotFound: 'Subcontratista no encontrado',
  subcontractors: 'Subcontratistas',
  subcontractorManagement: 'Gestión de subcontratistas',
  subscriptionHint:
    'Solo recibirás notificaciones por correo electrónico si has comprado los productos adecuados para tu ElevatorSense Box.',
  subscriptionText:
    'Elige las notificaciones de correo electrónico que deseas recibir.',
  subscriptions: 'Notificaciones',
  summarySubscription: 'Informe diario',
  summarySubscriptionExplanation: (
    <>
      El <b>informe diario</b> incluye un correo electrónico con un resumen de
      todos los edificios y ascensores seleccionados en el que se indica el
      número de errores.
    </>
  ),
  temperature: 'Temperatura',
  termsOfUse: {
    general: {
      title: 'GENERAL TERMS AND CONDITIONS OF USE OF ELEVATORSENSE APPLICATION',
      sections: [
        {
          title: 'I. General',
          content: [
            <>
              Wittur GmbH (en adelante <strong>"WITTUR"</strong>,{' '}
              <strong>"Nosotros"</strong>) ofrece a los usuarios (
              <strong>"Usuario"</strong>), ya sea directamente o a través de
              Afiliadas (según se define más adelante), una solución que
              consiste en hardware que recopila datos de ascensores de pasajeros
              y de carga en edificios o en sus componentes (la{' '}
              <strong>"ElevatorSense Box"</strong>), así como de la aplicación
              móvil y web de ElevatorSense (en adelante{' '}
              <strong>"Mobile App"</strong>, <strong>"Web App"</strong> y, en
              conjunto, <strong>"App"</strong>). (Tanto la ElevatorSense Box
              como la App se denominan en lo sucesivo{' '}
              <strong>"ElevatorSense Solution"</strong>).
            </>,
            <>
              La provisión de la ElevatorSense Box se regirá por un contrato
              separado entre el Usuario y WITTUR (
              <strong>"Contrato de la Box"</strong>).
            </>,
            <>
              La App también puede ser ofrecida por Afiliadas de WITTUR,
              manteniendo WITTUR su calidad de única parte contratante del
              Usuario (según se define más adelante) en tal escenario con
              respecto a la provisión de la App. <strong>"Afiliada"</strong>{' '}
              significa todas las empresas que son propiedad mayoritaria o que
              involucran una participación mayoritaria de acciones o intereses
              de capital entre sí, empresas dependientes y controladoras,
              empresas bajo la dirección unificada de una sola empresa
              controladora, y empresas que poseen acciones o participaciones
              entre ellas.
            </>,
            'WITTUR proporciona la App exclusivamente a empresarios. Un empresario es una persona física o jurídica, o una sociedad con capacidad legal que, al celebrar un acto jurídico, actúa en el ejercicio de su comercio, negocio o profesión. Los consumidores no pueden usar la App.',
            <>
              Los siguientes términos rigen completamente la relación legal
              entre WITTUR y el Usuario con respecto a la App (
              <strong>"Términos de uso"</strong>) y cubren{' '}
              <i>entre otras cosas</i> el acceso y el uso de la App, sujetos a
              términos adicionales o divergentes según se especifica en el{' '}
              <a href="#appendix_1">Apéndice 1</a>. Los Términos de uso se
              vuelven vinculantes al completarse el registro por parte del
              Usuario y en ese momento se concluye un contrato de servicio con
              WITTUR (<strong>"Contrato"</strong>). Los Términos de uso son
              almacenados por WITTUR y están accesibles para el Usuario en
              cualquier momento dentro de la App.
            </>,
            'Como empresario, el Usuario no tiene derecho de desistimiento.',
          ],
        },
        {
          title: 'II. Alcance',
          content: [
            <>
              La App es una aplicación móvil y web que permite servicios según
              lo descrito en el Contrato de la Box (<strong>"Servicios"</strong>
              ). La App también proporciona información, visualizaciones,
              notificaciones, instrucciones y evaluaciones sobre las medidas
              apropiadas que se deben tomar (<strong>"Recomendaciones"</strong>
              ). Junto con las Recomendaciones, se proporciona al Usuario un
              hipervínculo a la tienda web de WITTUR para componentes de la
              Solución ElevatorSense.
            </>,
            <>
              El uso de la App está sujeto exclusivamente a estos Términos de
              Uso, así como a la información proporcionada dentro de la App. Las
              condiciones diferentes o conflictivas no formarán parte de un
              acuerdo con WITTUR, a menos que WITTUR las haya aceptado
              expresamente por escrito. Sin perjuicio de la frase anterior, el
              Usuario es consciente de que, además de estos Términos de Uso,
              puede ser necesario cumplir con otros términos de uso del operador
              de la tienda de aplicaciones correspondiente (por ejemplo, Google
              en el caso de Google Play Store o Apple en el caso de Apple App
              Store) si el Usuario hace uso de la App Móvil descargada de dicha
              tienda, y que WITTUR no tiene ninguna influencia sobre esos
              términos adicionales, por lo que no asume ninguna responsabilidad
              al respecto.
            </>,
            'La descarga de la App Móvil requiere dispositivos terminales adecuados y acceso a Internet, lo que puede generar costos de conexión a Google Play Store / Apple App Store.',
            <>
              Las Partes reconocen y aceptan que la prestación de los Servicios
              en las jurisdicciones locales especificadas en{' '}
              <a href="#appendix_1">Apéndice 1</a> requiere la inclusión de
              términos o requisitos adicionales o divergentes que se suman o
              sustituyen a los establecidos en estos Términos de Uso. Por lo
              tanto, la relación contractual entre las Partes está sujeta a
              términos adicionales o divergentes según se especifica en{' '}
              <a href="#appendix_1">Apéndice 1</a>, si corresponde (en adelante:{' '}
              <strong>"Términos de Jurisdicción Local"</strong>). En caso de
              conflicto entre estos Términos de Uso y los Términos de
              Jurisdicción Local, prevalecerán estos últimos.
            </>,
          ],
        },
        {
          title: 'III. Remuneración',
          content: [
            'El uso de la funcionalidad básica de la App es gratuito. Sin embargo, el Usuario es consciente de que la App solo es utilizable junto con la ElevatorSense Box, para la cual existen diferentes modelos de compra o alquiler que están sujetos al Contrato de la Box.',
            <>
              Pueden aplicarse cargos adicionales por funciones especiales
              disponibles dentro de la App, que los Usuarios pueden adquirir (
              <strong>"Funciones"</strong>). Las Funciones disponibles para el
              Usuario pueden variar según el país en el que se utilice la App.
            </>,
          ],
        },
        {
          title: 'IV. Provisión de la App; Mantenimiento',
          content: [
            <>
              WITTUR proporcionará acceso razonable y uso de la App. Con este
              fin, WITTUR pondrá la App a disposición de empleados permanentes
              y/o autónomos registrados del Usuario (
              <strong>"Personal Autorizado"</strong>).
            </>,
            'A criterio exclusivo y absoluto de WITTUR, se podrá restringir el acceso a la App total o parcialmente, temporal o permanentemente, debido a trabajos de mantenimiento, problemas de capacidad u otros eventos fuera de su control. Normalmente, WITTUR realizará el mantenimiento de la App fuera del horario comercial normal, a menos que un evento inmediato requiera, a exclusivo criterio de WITTUR, mantenimiento durante el horario comercial normal. Cualquier parte de la App puede cambiar sin previo aviso y en cualquier momento, a exclusivo criterio de WITTUR.',
            'WITTUR proporciona acceso a la Web App en un entorno alojado, lo que permite al Usuario utilizar la App a través de Internet sin tener que instalar y operar el software en su propia infraestructura de TI.',
            'WITTUR hará esfuerzos razonables para mantener la disponibilidad de la Web App para el uso del Usuario en el punto de enlace entre la Internet pública y la red de los servidores de alojamiento de WITTUR. La Web App se considera disponible si puede utilizarse en el punto de enlace entre la Internet pública y la red de los servidores de alojamiento de WITTUR.',
            <>
              WITTUR proporciona actualizaciones de la App de acuerdo con el
              estado reconocido de la técnica sin remuneración adicional (
              <strong>"Actualizaciones"</strong>). Las Actualizaciones, en el
              sentido de estos Términos de Uso, se caracterizan por correcciones
              de errores u otras mejoras menores sin funcionalidades nuevas
              significativas. Las Actualizaciones no incluyen la entrega o
              provisión de nuevas funcionalidades o componentes adicionales que
              se ofrezcan por separado.
            </>,
            <>
              El Usuario debe instalar las Actualizaciones de la App Móvil (es
              decir, del lado del cliente) tan pronto como estén disponibles.
              Las Actualizaciones permiten que la App Móvil reciba información
              actualizada y aseguran su correcto funcionamiento. De lo
              contrario, WITTUR no puede garantizar que la App Móvil funcione
              correctamente o que la información proporcionada esté actualizada.
              Si el Usuario no instala las Actualizaciones pero continúa
              utilizando la versión obsoleta de la App Móvil, el Usuario
              renuncia a cualquier reclamo relacionado con defectos y a las
              reclamaciones por daños a las que pudiera tener derecho.
            </>,
          ],
        },
        {
          title: 'V. Cuenta de Usuario',
          content: [
            <>
              El acceso a la App solo puede otorgarse al Usuario y a su Personal
              Autorizado después de registrarse y crear una cuenta de usuario
              dentro de la App (<strong>"Cuenta de Usuario"</strong>).
            </>,
            'El Usuario debe comunicar a WITTUR el Personal Autorizado.',
            'El Usuario es el único y exclusivo responsable de gestionar su Personal Autorizado, por ejemplo, asignando roles y derechos dentro de la App. En particular, el Usuario deberá revocar el acceso a la App una vez que las personas correspondientes ya no trabajen para el Usuario.',
            <>
              El Usuario está obligado a mantener en secreto los datos de acceso
              a su Cuenta de Usuario y protegerlos del acceso de terceros. Si el
              Usuario tiene conocimiento de la pérdida o el uso indebido de sus
              datos de acceso o sospecha un uso indebido de su cuenta de
              usuario, debe notificar a WITTUR inmediatamente y cambiar la
              contraseña utilizando la funcionalidad proporcionada en la App.
              Está prohibido divulgar los datos de acceso a terceros. El Usuario
              no puede usar los datos de acceso de otra persona para acceder a
              la App. El Usuario es el único y exclusivo responsable de las
              actividades de cualquier persona que acceda a la App utilizando
              los datos de acceso del Usuario, incluido el Personal Autorizado,
              incluso si dichas actividades no fueron, de hecho, autorizadas por
              el Usuario.
            </>,
          ],
        },
        {
          title: 'VI. Obligaciones del Usuario',
          content: [
            <>
              El Usuario es el único y exclusivo responsable de cualquier
              contenido que cargue, publique o ponga a disposición del público a
              través de la App. Al usar una cuenta en la App, el Usuario declara
              y garantiza a WITTUR que la información que proporciona a WITTUR (
              <strong>"Contenido del Usuario"</strong>) es verdadera, exacta y
              completa. Además, el Usuario garantiza que mantendrá el Contenido
              del Usuario como verdadero, exacto y completo. WITTUR no supervisa
              el Contenido del Usuario en cuanto a integridad, exactitud,
              legalidad, disponibilidad, calidad o idoneidad para ningún
              propósito.
            </>,
            <>
              Los Servicios solo pueden realizarse mientras la ElevatorSense Box
              esté conectada a Internet. El Usuario reconoce que es su única y
              exclusiva responsabilidad habilitar técnicamente la ElevatorSense
              Box. Por la presente, el Usuario renuncia a cualquier derecho y a
              las reclamaciones resultantes a las que pudiera tener derecho
              debido a la desconexión de la ElevatorSense Box.
            </>,
            'Para realizar los Servicios, el Usuario está obligado a seguir estrictamente las instrucciones de instalación y operación proporcionadas en la App.',
            <>
              Si WITTUR proporciona actualizaciones y/o mejoras descargables
              para la ElevatorSense Box, el Usuario está obligado a instalarlas
              para asegurar la correcta interacción entre la App y la
              ElevatorSense Box. De lo contrario, WITTUR no puede garantizar que
              los Servicios puedan llevarse a cabo correctamente. Si el Usuario
              no instala una actualización y/o mejoras proporcionadas, pero
              continúa utilizando la versión obsoleta de la ElevatorSense Box,
              el Usuario renuncia así a cualquier reclamación relacionada con
              defectos y a cualquier reclamación por daños a las que pudiera
              tener derecho.
            </>,
            'Se prohíbe al Usuario realizar cualquier actividad en o en relación con la App que viole la legislación aplicable o infrinja los derechos de terceros.',
            {
              text: 'Además, se prohíbe al Usuario realizar las siguientes actividades, independientemente de cualquier violación de la ley:',
              list: [
                'la distribución de virus, troyanos y otros archivos dañinos;',
                'el envío de correo basura o spam y cartas en cadena;',
                'la difusión de contenido o comunicación ofensivo, con orientación sexual, obsceno o difamatorio, así como cualquier contenido o comunicación que sean adecuados para promover o apoyar el racismo, el fanatismo, el odio, la violencia física o actos ilegales (ya sea de forma explícita o implícita en cada caso);',
                'la solicitud a otros usuarios de revelar contraseñas o datos personales con fines comerciales o ilegales;',
                'la difusión y/o reproducción pública de contenido disponible en la App.',
              ],
            },
            'WITTUR se reserva el derecho de suspender al Usuario del uso de la App en cualquier momento, si se violan estos Términos de Uso, la legislación aplicable o los derechos de terceros, o si WITTUR tiene motivos razonables para creer que pueden violarse.',
          ],
        },
        {
          title: 'VII. Derechos de Propiedad Intelectual',
          content: [
            <>
              WITTUR otorga al Usuario un derecho revocable, no exclusivo,
              intransferible, no sublicenciable y limitado en el tiempo a la
              duración del Contrato, para usar la App de conformidad con estos
              Términos de Uso (<strong>"Licencia"</strong>). El titular de los
              derechos de propiedad intelectual sobre la App es WITTUR, o sus
              socios comerciales u otros terceros que hayan puesto a disposición
              de WITTUR el contenido y los derechos de propiedad intelectual
              correspondientes bajo licencia. Por lo tanto, el Usuario no podrá
              modificar, ampliar, editar, copiar ni distribuir de otro modo la
              App ni el contenido disponible en la App.
            </>,
            <>
              Las recomendaciones generadas por la App pueden ser utilizadas por
              el Usuario para apoyar sus propios servicios hacia sus clientes.
              WITTUR no asumirá ninguna responsabilidad u obligación con
              respecto a dichos servicios ni a las acciones del Usuario o de sus
              clientes que se deriven de los mismos.
            </>,
            <>
              A menos que se disponga lo contrario en estos Términos de Uso, el
              Usuario se compromete a no reproducir, distribuir, modificar ni
              crear obras derivadas de la App o de cualquier otro componente de
              la misma, y a no realizar ingeniería inversa ni descompilar la
              App, salvo que esté autorizado por ley imperativa.
            </>,
            <>
              El Usuario reconoce y acepta que WITTUR está autorizada a utilizar
              los datos procesados a través de la App en forma anonimizada (de
              acuerdo con la legislación de protección de datos aplicable) para
              cualquier fin comercial de WITTUR y, en particular, a
              reproducirlos y conceder acceso a terceros a dichos datos. WITTUR
              no desanonimizará los datos y está obligada a tomar todas las
              medidas adecuadas para evitar cualquier desanonimización de los
              datos. Si se otorga acceso a los datos a terceros, WITTUR deberá
              exigirles que no desanonimicen los datos y que tomen todas las
              medidas adecuadas para evitar cualquier desanonimización. En la
              medida necesaria, el Usuario otorgará a WITTUR una licencia
              irrevocable, mundial, no exclusiva, libre de regalías y
              sublicenciable para utilizar dichos datos. Los fines comerciales
              incluirán, entre otros, el desarrollo, la fabricación, la mejora
              y/o la comercialización de productos y servicios.
            </>,
          ],
        },
        {
          title: 'VIII. Duración y Terminación',
          content: [
            <>
              El Contrato tiene una duración ilimitada y el Usuario tendrá
              derecho a rescindir el Contrato en cualquier momento por
              conveniencia. El Usuario ha reconocido y es consciente de (i) que
              la funcionalidad de la App se limitará o suspenderá en el momento
              en que finalice el período de suscripción y/o los derechos de uso
              de la ElevatorSense Box en virtud del Contrato de la Box, y (ii)
              que la terminación por conveniencia del Contrato por parte del
              Usuario no afectará las obligaciones que aún puedan derivarse del
              Contrato de la Box.
            </>,
            <>
              A menos que se acuerde lo contrario con el Usuario, las licencias
              de las Funciones adquiridas por el Usuario son válidas hasta el
              final del respectivo periodo de dicha Función. Si el Usuario no
              rescinde la licencia de una Función tres (3) meses antes de que
              finalice el periodo, éste se prorrogará automáticamente por doce
              (12) meses y el Usuario estará obligado a pagar los cargos
              adicionales acordados para dicho periodo. El derecho a rescindir
              una licencia de una Función con efecto inmediato por causa
              justificada permanece intacto. La Sección VIII.1 se aplicará
              mutatis mutandis a las Funciones.
            </>,
            <>
              Al finalizar el Contrato, el Usuario deberá (i) cesar
              inmediatamente el uso de la App y reconoce que WITTUR puede
              bloquear el acceso del Usuario y de su Personal Autorizado a la
              misma, y (ii) inmediatamente, pero a más tardar en un plazo de 5
              (cinco) días, eliminar y destruir cualquier contenido descargado y
              material impreso. El Usuario no tiene ningún derecho, título o
              interés (ni derechos de autor, marca registrada u otros derechos
              de propiedad intelectual) sobre la App o sobre cualquier
              contenido, información, materiales, aplicaciones u otras
              funcionalidades relacionadas con la misma.
            </>,
          ],
        },
        {
          title: 'IX. Derechos en Caso de Defectos',
          content: [
            <>
              La App se proporcionará y mantendrá en un estado adecuado para el
              uso contractual. La obligación de mantenimiento no incluye la
              adaptación de las funcionalidades de la propia App a condiciones
              operativas cambiantes y a desarrollos técnicos y funcionales que
              no estén relacionados con la ElevatorSense Box, la adaptación al
              alcance de las funciones de productos competidores o la creación
              de compatibilidad con nuevas versiones de navegadores.
            </>,
            {
              text: (
                <>
                  WITTUR garantiza que la App está libre de derechos de
                  terceros, en particular derechos de propiedad intelectual, que
                  limiten o impidan su uso de acuerdo con estos Términos de Uso.
                  En caso de cualquier alegación de que el uso de la App,
                  conforme a estos Términos de Uso, viole o infrinja la patente,
                  derechos de autor, marca u otros derechos de propiedad
                  intelectual de un tercero (<strong>"Infracción"</strong>)
                </>
              ),
              list: [
                <>
                  WITTUR tiene el derecho de modificar la App de manera que la
                  Infracción deje de aplicarse o de obtener la autorización para
                  que la App se use de conformidad con los Términos de Uso, sin
                  limitaciones y sin costos adicionales para el Usuario;
                </>,
                <>
                  el Usuario se compromete a proporcionar a WITTUR una
                  notificación inmediata por escrito (por ejemplo, por correo
                  electrónico) y toda la información relacionada con la
                  Infracción; y
                </>,
                <>
                  el Usuario cooperará y prestará asistencia a WITTUR para poner
                  fin a la Infracción, en la medida en que razonablemente se
                  pueda esperar de él.
                </>,
              ],
            },
            'El derecho a reclamar daños y perjuicios está sujeto a las limitaciones de responsabilidad de conformidad con la siguiente Sección X..',
          ],
        },
        {
          title: 'X. Limitación de Responsabilidad',
          content: [
            'WITTUR es responsable sin limitación de los daños causados por dolo o negligencia grave de WITTUR o de sus auxiliares, por los daños causados por lesiones a la vida, al cuerpo o a la salud, así como en el ámbito de las leyes de responsabilidad por productos aplicables.',
            <>
              Por daños y gastos causados por negligencia simple, WITTUR solo
              será responsable por los daños típicamente previsibles si dichos
              daños y gastos fueron causados por el incumplimiento, por parte de
              WITTUR, de obligaciones contractuales esenciales. Las obligaciones
              contractuales son esenciales si su cumplimiento es necesario para
              alcanzar el propósito del Contrato y el Usuario puede, por tanto,
              confiar en su cumplimiento.
            </>,
            'En todos los demás casos, WITTUR no será responsable.',
            <>
              A modo de aclaración, WITTUR no será en ningún caso responsable de
              un daño o pérdida si, y en la medida en que, dicho daño o pérdida
              surja del incumplimiento, por parte del Usuario, de las
              recomendaciones o avisos proporcionados por o contenidos en la
              App.
            </>,
          ],
        },
        {
          title:
            'XI. Limitaciones de Responsabilidad por el Contenido Proporcionado',
          content: [
            <>
              No existirá responsabilidad por cualquier información
              proporcionada en los Servicios y cualquier Recomendación
              proporcionada en la App (
              <strong>"Contenido Proporcionado"</strong>) por parte de WITTUR.
              Como se deriva de la Sección VII.2, WITTUR no será responsable con
              respecto al uso del Contenido Proporcionado por o para los propios
              clientes del Usuario.
            </>,
            <>
              Al proporcionar información en los Servicios, la información puede
              no ser precisa, ya que los datos generados por la ElevatorSense
              Box están sujetos a condiciones externas.
            </>,
            <>
              Al proporcionar Recomendaciones, la App ofrece ciertas
              probabilidades de un resultado particular y recomienda acciones en
              consecuencia. Sin embargo, la App no realiza ninguna evaluación
              concluyente. Hacer una evaluación debida, incluyendo la compra de
              productos ElevatorSense en la tienda web de WITTUR como
              reemplazos, sigue siendo responsabilidad única y exclusiva del
              Usuario.
            </>,
            {
              text: 'El Usuario debe tener en cuenta las siguientes limitaciones que se aplican al Contenido Proporcionado generado por esta App:',
              list: [
                <>
                  El Contenido Proporcionado se genera utilizando algoritmos
                  altamente complejos. Sin embargo, obviamente no es posible que
                  un algoritmo modele cada una de las características de un
                  evento individual. Por lo tanto, la App proporciona una
                  aproximación continuamente mejorada para ofrecer soporte a la
                  toma de decisiones personalizada.
                </>,
                <>
                  Datos nuevos y actualizados, que forman la base del Contenido
                  Proporcionado, se introducen constantemente en el algoritmo.
                  WITTUR compila estos datos con la mayor diligencia profesional
                  y según su mejor conocimiento. Sin embargo, no se puede
                  garantizar que los datos sean correctos, completos y estén
                  actualizados. Errores en un conjunto de datos podrían conducir
                  a Contenido Proporcionado poco fiable.
                </>,
              ],
            },
          ],
        },
        {
          title: 'XII. Protección de Datos',
          content: [
            <>
              Dado que la protección de datos es una alta prioridad para WITTUR,
              WITTUR cumple estrictamente con las leyes de protección de datos
              aplicables al procesar (por ejemplo, recopilar, utilizar,
              divulgar, etc.) datos personales. Si y en la medida en que no se
              informe de otro modo sobre el procesamiento específico de datos
              personales por parte de WITTUR, la información sobre dicho
              procesamiento se incluye en la política de privacidad aplicable,
              la cual es accesible en la App.
            </>,
            'El Usuario deberá cumplir con las leyes de protección de datos aplicables al proporcionar datos personales a WITTUR, por ejemplo, a través de la App.',
          ],
        },
        {
          title: 'XIII. Cambios / Transferencia del Contrato',
          content: [
            <>
              WITTUR está facultada para modificar estos Términos de Uso en
              cualquier momento. El Usuario será notificado de dichos cambios
              con al menos sesenta (60) días calendario de antelación a la
              implementación prevista de los cambios. Si el Usuario no se opone
              dentro de los treinta (30) días calendario desde la recepción de
              la notificación y continúa utilizando la App después de la
              expiración del período de objeción, se considerará que los cambios
              han sido válidamente acordados a partir del vencimiento de dicho
              período. En dicha notificación, se informará al Usuario sobre su
              derecho de oposición y las consecuencias del mismo.
            </>,
            <>
              WITTUR se reserva el derecho de transferir este Contrato con el
              Usuario a otra empresa. Los Usuarios serán informados sobre dicha
              transferencia y podrán rescindir el Contrato dentro de los diez
              (10) días calendario posteriores a haber recibido la información
              sobre la transferencia.
            </>,
          ],
        },
        {
          title: 'XIV. Disposiciones Varias',
          content: [
            <>
              Si una o más disposiciones de estos Términos de Uso fueran
              inválidas o no ejecutables debido a la violación de la ley
              aplicable u otra razón, el resto de los Términos de Uso seguirá
              siendo válido. La disposición inválida o no ejecutable será
              reemplazada por una disposición válida y ejecutable, que refleje
              lo más fielmente posible el entendimiento mutuo de las partes.
            </>,
            <>
              La ley que rige la validez, interpretación y ejecución de estos
              Términos de Uso es la ley alemana, con exclusión de sus normas
              sobre conflicto de leyes, y los tribunales competentes para
              conocer de cualquier disputa que surja de o en relación con estos
              Términos de Uso son los tribunales de Múnich.
            </>,
          ],
        },
        {
          title: 'XV. Usuarios Internacionales',
          content: [
            <>
              Esta App es operada, supervisada y actualizada por WITTUR GmbH.
              Está destinada únicamente a ser utilizada en los países enumerados
              en
              <a href="#appendix_1">Apéndice 1</a>. Si el Usuario accede a esta
              App y/o descarga contenido desde fuera de estos países, el Usuario
              es el único y exclusivo responsable de asegurarse de que dicho uso
              cumpla con la legislación local pertinente.
            </>,
          ],
        },
      ],
    },
    appendix: {
      title:
        'Apéndice 1: PAÍS DE DISTRIBUCIÓN Y TÉRMINOS DE JURISDICCIÓN LOCAL',
      content: [
        {
          heading:
            'Términos Adicionales o Divergentes para el País de Distribución',
          details: [
            {
              country: 'India',
              content: [
                'La Sección V. (Cuenta de Usuario) se complementa de la siguiente manera:',
                <>
                  El Usuario consiente que WITTUR o sus Afiliadas recopilen su
                  contraseña con el fin de habilitar el acceso y el uso de la
                  App. La contraseña puede ser tratada por WITTUR o sus
                  Afiliadas de acuerdo con la Política de Privacidad.
                </>,
                <>
                  La Sección X.2. (Limitación de Responsabilidad) se modifica de
                  la siguiente manera: Por daños y gastos causados por
                  incumplimiento material de WITTUR, WITTUR será responsable
                  únicamente de aquellos daños que surjan de forma natural en el
                  curso normal de dicho incumplimiento, o de los que el Usuario
                  sabía, al aceptar los Términos de Uso, que probablemente
                  resultarían de dicho incumplimiento y (i) no incluirán daños
                  que surjan de pérdidas o daños remotos e indirectos causados
                  por el incumplimiento material y (ii) estarán limitados a la
                  contraprestación recibida del Usuario por la ElevatorSense
                  Solution, lo que el Usuario y WITTUR acuerdan que es una
                  estimación previa justa, razonable y realista de los daños que
                  puedan surgir por motivo de dicho incumplimiento material.
                </>,
              ],
            },
            {
              country: 'Reino Unido',
              content: [
                <>
                  La Sección X.2. (Limitación de Responsabilidad) se modifica de
                  la siguiente manera:
                  <br /> WITTUR solo será responsable de los daños y gastos
                  típicamente previsibles si estos daños y gastos fueron
                  causados por un incumplimiento material de WITTUR, el cual
                  WITTUR no haya subsanado dentro de los treinta (30) días
                  posteriores a la recepción de la notificación de
                  incumplimiento por parte del Cliente. Sujeto a lo anterior,
                  WITTUR excluye cualquier otra responsabilidad (ya sea directa
                  o indirecta, independientemente de la causa) bajo estos
                  Términos de Uso.
                </>,
              ],
            },
          ],
        },
      ],
    },
  },
  testCompleted: 'Prueba finalizada.',
  testResults: 'Resultados de la prueba',
  time: 'Hora',
  tooHigh: 'Demasiado alto',
  tooLoose: 'Demasiado suelto',
  tooLow: 'Demasiado bajo',
  tooTight: 'Demasiado tenso',
  top: 'arriba',
  topToBottom: 'De arriba hacia abajo',
  touching: 'en contacto',
  transformer: 'Transformador',
  truckRoller: 'Ruedas de carro',
  truckRollerInferior: 'Ruedas de carro inferior',
  truckRollerSuperior: 'Ruedas de carro superior',
  twisted_cd_panels: 'Hojas de puerta de cabina no alineadas',
  twisted_ld_panels: 'Hojas no alineadas',
  unlinkBox: 'Desenlazar caja',
  unreadNotifications: (n: number) =>
    `${n} notificaciones sin leer${n === 1 ? '' : 's'}`,
  unshare: 'Dejar de compartir',
  update: 'Actualizar',
  updateRequired: 'Actualización necesaria',
  updateStaticData: 'Actualizar datos',
  updating: 'Actualizando...',
  upToDate: 'Hasta la fecha',
  usage: 'Uso',
  usedElectronics: 'Ascensores utilizados',
  validFrom: 'Válido desde',
  validUntil: 'Válido hasta',
  vibration: 'Vibración',
  viewErrorLog: 'Ver Registro de errores',
  waitWhileInitializingTest: 'Espere mientras se inicia la prueba...',
  waitingForData: 'Esperando a que lleguen los nuevos datos',
  waitingForTestResults: 'Esperando los resultados de la prueba...',
  warning: 'Advertencia',
  warnings: 'Advertencias',
  warrantyExtensionRequestedOn: (date: string) =>
    `La extensión de garantía fue solicitada en ${date}`,
  whyNotReplaced: `'Why was the component not replaced?'`,
  wifiScanned: 'WIFI ha sido escaneado',
  years: 'años',
  yes: 'Sí',
  zero_position: 'Posición cero',
  zero_position_description: 'La posición cero no es correcta.',
  zero_position_eco_actions:
    '"""Procedimiento ECO+: 1) Pon S1DIP1 en ON (modo Servicio). 2) Cierra la puerta manualmente (posición cero). 3) Pulsa el botón Aprendizaje > 10s (Longlearn). 4) Abre y cierra la puerta utilizando el botón de accionamiento de servicio. 5) Mantén pulsados los botones del extremo de apertura de la puerta y del extremo de cierre de la puerta durante > 2s. Resultado: El LED de estado debe apagarse después de 2 ciclos."""',
  zero_position_midi_actions:
    '" ""Procedimiento MIDI/SUPRAV2: Cierra la puerta manualmente (posición cero). Sigue las instrucciones del pictograma."""',
  yourBuilding: 'Su edificio',
  yourBuildings: 'Sus edificios',
};
